// Angular
import { Component, ChangeDetectorRef } from '@angular/core';
import { NotificationService } from '../../../services';
// Layout
import { OffcanvasOptions } from '../../../../core/_base/layout';
import { map } from 'rxjs/operators';
import { Observable,Subscription } from 'rxjs';


@Component({
	selector: 'kt-quick-panel',
	templateUrl: './quick-panel.component.html',
	styleUrls: ['./quick-panel.component.scss']
})
export class QuickPanelComponent {
	// Public properties
	offcanvasOptions: OffcanvasOptions = {
		overlay: true,
		baseClass: 'kt-quick-panel',
		closeBy: 'kt_quick_panel_close_btn',
		toggleBy: 'kt_quick_panel_toggler_btn'
	};
	notifications$;
	showSpinner: boolean = false;
	subscription: Subscription;

	//notifications$=this.notificationService.getAll().pipe(map(item=>item.items))
	constructor(private notificationService: NotificationService, public cd: ChangeDetectorRef) { }
	ngOnInit() {
		this.notifications$ = this.notificationService.getAll().pipe(map(item => item.items));
		this.subscription = this.notificationService.showLoader.subscribe(data => {
			this.showSpinner = data;
			if (data) {
				this.notifications$ = this.notificationService.getAll().pipe(map(item => item.items));
				if (!this.cd['destroyed']) {
					this.cd.detectChanges();
				}
				this.showSpinner = false;
			}
		});
		// this.notificationService.currentNotification.subscribe((data:Observable<any>) => {
		// 	this.notifications$ = data.pipe(map(item=>item.items));
		// 	this.showSpinner = true;
		// 	if (!this.cd['destroyed']) {
		// 		this.cd.detectChanges();
		// 	}
		// });

	}
	calculateCreatedTime(item) {
		let currentTime = new Date().getTime();
		let createdTime = new Date(item.created).getTime();
		let difference = (currentTime - createdTime);
		difference = difference / 1000;
		if (difference < 60) {
			return difference.toFixed(0) + ' Second' + this.makeTimePlural(difference.toFixed(0));
		}
		difference = difference / 60;
		if (difference < 60) {
			return difference.toFixed(0) + ' Minute' + this.makeTimePlural(difference.toFixed(0));
		}
		difference = difference / 60;
		if (difference < 24) {
			return difference.toFixed(0) + ' Hour' + this.makeTimePlural(difference.toFixed(0));
		}
		difference = difference / 24;
		if (difference < 365) {
			return difference.toFixed(0) + ' Day' + this.makeTimePlural(difference.toFixed(0));
		}
		difference = difference / 365;
		return difference.toFixed(0) + ' Year' + this.makeTimePlural(difference.toFixed(0));
	}
	makeTimePlural = (time) => time == 1 ? '' : 's';

	get loadingCaption() { return { titleEn: 'Loading...Please wait', titleBn: 'অনুগ্রহ করে অপেক্ষা করুন...' } }

	ngOnDestroy() {
		this.subscription.unsubscribe();
	  }
	
}
