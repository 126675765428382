import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../core/auth";

@Injectable({ providedIn: "root" })
export class DistrictService {
	apiName = "Districts";
	constructor(private httpClient: HttpClient, private authService: AuthService) {}

	getDistrictByDistId(districtId: number) {
		return this.httpClient.get<any>(`${environment.apiUrl}${this.apiName}/${districtId}`, this.authService.getRequestHeader());
	}

	getByDivisionId(divisionId: number, locationType = 0) {
		return this.httpClient.get<any>(`${environment.apiUrl}${this.apiName}?divisionId=${divisionId}&locationType=${locationType}&pageSize=${300}`, this.authService.getRequestHeader());
	}

	getAllDistrict() {
		return this.httpClient.get<any>(`${environment.apiUrl}${this.apiName}?PageSize=100`, this.authService.getRequestHeader());
	}
}
