import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UserListComponent } from './list/user-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCardModule, MatSelectModule } from '@angular/material';
import { UserEditComponent } from './edit/user-edit.component';
import { MustMatchDirective } from '../must-match/must-match.directive';
import { ConfirmDialogModule } from '../../../dialog/confirm-dialog/confirm-dialog.module';
import { DraftDialogModule } from '../../../dialog/draft-dialog/draft-dialog.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ConfirmDialogModule,
        ReactiveFormsModule,
        TranslateModule,
        MatSelectModule,
        NgbPaginationModule,
        MatCardModule,
        RouterModule
    ],
    declarations: [UserListComponent,UserEditComponent,MustMatchDirective],
    exports: [
        UserListComponent,UserEditComponent
    ]
})
export class UserSharedModule {

}