import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DraftDialogModel, DraftDialogComponent } from '../dialog/draft-dialog/draft-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Injectable()
export class DraftDialogService {
  constructor(public dialog: MatDialog, private router: Router) {
  }
  confirm(title: string,formType:string, message: string,reject:string,proceed:string): Observable<boolean> {

    const dialogData = new DraftDialogModel(title,formType, message,reject,proceed);
    const dialogRef = this.dialog.open(DraftDialogComponent, {
      maxWidth: "1500px",
      data: dialogData,
      disableClose: false,
      closeOnNavigation: true
    });
    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });
    return dialogRef.afterClosed();
  }
}
