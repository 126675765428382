// USA
export const locale = {
	lang: "bn",
	data: {
		USER: {
			EDIT: {
				TITLE: "নতুন ব্যবহারকারী",
				EDIT_PROFILE: "ইডিট প্রোফাইল",
				BUTTONCAPTION: {
					BACK: "ব্যবহারকারীর তালিকা যান",
				},
				NOTIFICATION: {
					ADD: "ব্যবহারকারী সফলভাবে যোগ করা হয়েছে !!",
					UPDATE: "ব্যবহারকারী সফলভাবে আপডেট করা হয়েছে!!",
					DELETE: "ব্যবহারকারী সফলভাবে মুছে ফেলা হয়েছে!!",
					ERROR: "ত্রুটি: কোনো ডেটা আপডেট করা হয়নি৷",
				},
				CONTROLCAPTION: {
					OFFICETYPE: {
						NAME: "অফিসের টাইপ",
						PLACEHOLDER: "পদবি লিখুন",
					},
					OFFICENAME: {
						NAME: "অফিসের নাম",
						PLACEHOLDER: "স্থিতি লিখুন",
					},
					USERNAME: {
						NAME: "ইউসার নাম",
						PLACEHOLDER: "ব্যবহারকারীর নাম লিখুন",
					},
					FIRSTNAME: {
						NAME: "নামের প্রথম অংশ/প্রদত্ত নাম",
						PLACEHOLDER: "নামের প্রথম অংশ/প্রদত্ত নাম লিখুন",
					},
					LASTNAME: {
						NAME: "নামের শেষাংশ/বংশগত নাম",
						PLACEHOLDER: "নামের শেষাংশ/বংশগত নাম লিখুন",
					},
					PASSWORD: {
						NAME: "পাসওয়ার্ড",
						PLACEHOLDER: "পাসওয়ার্ড লিখুন",
					},
					CONFIRMPASSWORD: {
						NAME: "পাসওয়ার্ড নিশ্চিত করুন",
						PLACEHOLDER: "পাসওয়ার্ড লিখুন",
					},
					EMAIL: {
						NAME: "ইমেইল",
						PLACEHOLDER: "ইমেইল লিখুন",
					},
					ROLES: {
						NAME: "রোল",
						PLACEHOLDER: "রোল",
					},
					PHONE: {
						NAME: "ফোন নম্বর",
						PLACEHOLDER: "ফোন নম্বর লিখুন",
					},
					EMPLOYEEID: {
						NAME: "কর্মচারী আইডি",
						PLACEHOLDER: "কর্মচারী আইডি লিখুন",
					},
					DESIGNATION: {
						NAME: "উপাধি",
						PLACEHOLDER: "উপাধি লিখুন",
					},
					STATUS: {
						NAME: "স্টেটাস",
						PLACEHOLDER: "স্টেটাস লিখুন",
					},
				},
				STATUS: {
					ACTIVE: "সচল",
					INACTIVE: "সচল নয় ",
				},
				PICTURE: {
					PICTURELABEL: "ছবি",
					CHOOSEPICTURE: "ছবি নির্বাচন করুন",
				},
				SIGNATURE: {
					SIGNATURELABEL: "স্বাক্ষর",
					CHOOSESIGNATURE: "স্বাক্ষর নির্বাচন করুন",
				},
			},
		},
	},
};
