import { OfficeTypeModel } from './office-type.model';
import { OfficeCatchmentEditModel } from '..';

export class OfficeModel {
    id: number;
    officeId: string;
    officeType:OfficeTypeModel;
    officeLevel: string;
    nameBn: string;
    nameEn: string;
    address: string;
    createdBy: string;
    modifiedBy: string;
    isActive: boolean;
    created: Date;
    modified: Date;
    parentOfficeId: number;
    parentOffice:OfficeModel;
    officeCatchments:OfficeCatchmentEditModel[]
}

export class OfficeSearch {
	officeTypeId: number;
	divisionId: number;
	districtId: number;
	upazillaId: number;
}