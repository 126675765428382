import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UserUtilityService {
    constructor() {

    }
    getDesignation(){
        let designations=[
            {id:'1', name:'SUPERVISOR'},
        ];
        return designations;
    }
    getStatuses(){
        let statuses=[
            {id:1,name:'ACTIVE', value:'Active'},
            {id:2,name:'INACTIVE', value:'Inactive'}
        ]
        return statuses;
    }

}