import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiResponseModel } from '../model/shared/api-response.model';
import { AuthService } from '../../core/auth';
import { AccessEnum, FormPermissionEditModel, FormPermissionEditModels, } from '../model';


@Injectable({
	providedIn: 'root',
})
export class FormsService {
	pageModel = {
		currentPage: 1,
		pageSize: 20
	}
	apiName = 'Forms';
	constructor(private httpClient: HttpClient, private authService: AuthService) {

	}
	getAll<ApiResponseModel>(pageNumber: number, pageSize: number) {
		return this.httpClient.get<any>(environment.apiUrl + this.apiName + '?Page=' + pageNumber + '&PageSize=' + pageSize, this.authService.getRequestHeader());
	}
	getRefferableForms<ApiResponseModel>(pageNumber: number, pageSize: number, isReferable: boolean = true) {
		return this.httpClient.get<any>(environment.apiUrl + this.apiName + '?Page=' + pageNumber + '&PageSize=' + pageSize + '&IsReferable=' + isReferable, this.authService.getRequestHeader());
	}
	changeStatus(formId: number, statusChangeItem: any) {
		return this.httpClient.patch(environment.apiUrl + this.apiName + '/' + formId, statusChangeItem, this.authService.getRequestHeader());
	}
	getById(formId: number) {
		return this.httpClient.get<any>(environment.apiUrl + this.apiName + '/' + formId, this.authService.getRequestHeader());
	}
	getPdf(formId: number) {
		return this.httpClient.get<any>(environment.apiUrl + this.apiName + '/' + formId+'/Download', this.authService.getBlobRequestHeader());
	}
	getUserAccessibleFormByType(formType: string) {
		return this.httpClient.get<any>(environment.apiUrl + this.apiName + '/Permissions?formType=' + formType + "&PageSize=34", this.authService.getRequestHeader());
	}
	save(formId: number, formPermissionEditModel: FormPermissionEditModel) {
		return this.httpClient.post<any>(environment.apiUrl + this.apiName + '/' + formId + '/Permissions', formPermissionEditModel, this.authService.getRequestHeader());
	}
	update(formId: number, formPermissionEditModel: FormPermissionEditModel) {
		console.log(formPermissionEditModel);
		return this.httpClient.put<any>(environment.apiUrl + this.apiName + '/' + formId + '/Permissions', formPermissionEditModel, this.authService.getRequestHeader());
	}

	formPermission = {
		convertToViewFormat: (roles: any, formPermission: any) => {
			console.log(formPermission);
			var rolesPermission = [];
			roles.forEach(element => {
				var permission = {
					roleId: element.id,
					roleName: element.name,
					canView: false,
					canDelete: false,
					canEdit: false,
					canSubmit: false,
					canApprove: false,
					canAssign: false,
					canTransfer: false,
					canCancel: false,
					canReject: false,
					getNotification: false,
					dataExport: false
				}
				if (formPermission && formPermission.length > 0) {
					var rolePermission = formPermission.filter(x => x.roleId === element.id);
					if (rolePermission && rolePermission.length > 0) {
						rolePermission.forEach(p => {
							if (p.access === AccessEnum.View) {
								permission.canView = true;
							}
							else if (p.access === AccessEnum.Delete) {
								permission.canDelete = true;
							}
							else if (p.access === AccessEnum.Edit) {
								permission.canEdit = true;
							}
							else if (p.access === AccessEnum.Add) {
								permission.canSubmit = true;
							}
							else if (p.access === AccessEnum.Approve) {
								permission.canApprove = true;
							}
							else if (p.access === AccessEnum.Assign) {
								permission.canAssign = true;
							}
							else if (p.access === AccessEnum.Transfer) {
								permission.canTransfer = true;
							}
							else if (p.access === AccessEnum.Cancel) {
								permission.canCancel = true;
							}
							else if (p.access === AccessEnum.Reject) {
								permission.canReject = true;
							}
							else if (p.access === AccessEnum.GetNotification) {
								permission.getNotification = true;
							}
							else if (p.access === AccessEnum.DataExport) {
								permission.dataExport = true;
							}

						})
					}
				}
				rolesPermission.push(permission);
			});
			return rolesPermission;
		},
		convertToEditModel: (formPermissions: any): FormPermissionEditModel => {
			let formPermissionEditModels: FormPermissionEditModels[] = [];
			if (!formPermissions || formPermissions.length === 0)
				return null;
			formPermissions.forEach(item => {
				this.formPermission.pushToFormPermissionsArray(item, item.canView, AccessEnum.View, formPermissionEditModels);
				this.formPermission.pushToFormPermissionsArray(item, item.canDelete, AccessEnum.Delete, formPermissionEditModels);
				this.formPermission.pushToFormPermissionsArray(item, item.canEdit, AccessEnum.Edit, formPermissionEditModels);
				this.formPermission.pushToFormPermissionsArray(item, item.canSubmit, AccessEnum.Add, formPermissionEditModels);
				this.formPermission.pushToFormPermissionsArray(item, item.canApprove, AccessEnum.Approve, formPermissionEditModels);
				this.formPermission.pushToFormPermissionsArray(item, item.canAssign, AccessEnum.Assign, formPermissionEditModels);
				this.formPermission.pushToFormPermissionsArray(item, item.canTransfer, AccessEnum.Transfer, formPermissionEditModels);
				this.formPermission.pushToFormPermissionsArray(item, item.canCancel, AccessEnum.Cancel, formPermissionEditModels);
				this.formPermission.pushToFormPermissionsArray(item, item.canReject, AccessEnum.Reject, formPermissionEditModels);
				this.formPermission.pushToFormPermissionsArray(item, item.getNotification, AccessEnum.GetNotification, formPermissionEditModels);
				this.formPermission.pushToFormPermissionsArray(item, item.dataExport, AccessEnum.DataExport, formPermissionEditModels);
			});
			var formPermissionEditModel = new FormPermissionEditModel();
			formPermissionEditModel.formPermissions = formPermissionEditModels;
			return formPermissionEditModel;
		},
		pushToFormPermissionsArray: (item: any, isAccess: boolean, accessCode: string, formPermissionEditModels: FormPermissionEditModels[]) => {
			if (isAccess) {
				var permission = new FormPermissionEditModels();
				permission.id = 0;
				permission.roleId = item.roleId;
				permission.roleName = item.roleName;
				permission.access = accessCode;
				formPermissionEditModels.push(permission);
			}
		}

	}

}