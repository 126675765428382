import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../core/auth";

@Injectable({ providedIn: "root" })
export class UpazillaService {
	apiName = "Upazillas";

	constructor(private httpClient: HttpClient, private authService: AuthService) {}

	// getByDistrictId(districtId:number) {
	//     return this.httpClient.get<any>(environment.apiUrl + this.apiName+'?PageSize=200&districtId='+districtId,this.authService.getRequestHeader());
	// }

	getByUpazillaId(upazillaId) {
		return this.httpClient.get<any>(`${environment.apiUrl}${this.apiName}/${upazillaId}`, this.authService.getRequestHeader());
	}

	getByDistrictId(districtId, locationType = 0) {
		return this.httpClient.get<any>(`${environment.apiUrl}${this.apiName}?PageSize=200&districtId=${districtId}&locationType=${locationType}&pageSize=${300}`, this.authService.getRequestHeader());
	}
}
