import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth';


import { BehaviorSubject } from 'rxjs';
import { Console } from 'console';
@Injectable()
export class MessagingService {
  apiName = 'FirebaseTokens';
  currentMessage = new BehaviorSubject(null);
  constructor(private angularFireMessaging: AngularFireMessaging,private httpClient: HttpClient,private authService:AuthService) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        _messaging.onBackgroundMessage = _messaging.onBackgroundMessage.bind(_messaging); 
      }
    )
  }

  
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log("Requesting Token");
        console.log(token);
        console.log("Sending token to server");
        this.sendTokenToServer(token).subscribe((response)=> {
          console.log(response);
        });
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("Message receive in foreground",payload);
        this.currentMessage.next(payload);
        this.showCustomNotification(payload);
      })
  }



  showCustomNotification(payload:any) {
    let notify_data = payload['data'];
    let title = notify_data['notificationType']
    // let option = {
    //   body:notify_data['notificationBn']
    // }
    let notificationOptions = {
      body: notify_data.notificationBn,
      icon: '/assets/media/logos/BD_logo.png',
      image: '/assets/media/logos/unicef-logo.png'
      };
    let notify:Notification = new Notification(title,notificationOptions);
    
  }

  
  sendTokenToServer(token:any) {
    let tokenObject = {
      "token" : token
    }
    return this.httpClient.post<any>(environment.apiUrl + this.apiName,tokenObject, this.authService.getRequestHeader())  
  }

}