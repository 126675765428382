import { Component, ChangeDetectorRef, Input } from "@angular/core";
import { UserUtilityService } from "../../../services/user-utility.service";
import { ActivatedRoute } from "@angular/router";
import { OfficeService, RoleService, UserService } from "../../../services";
import { OfficeModel, NotificationEnum, ChangePasswordModel } from "../../../model";
import { TranslationService } from "../../../../core/_base/layout";
import { DomSanitizer } from "@angular/platform-browser";
import { FormControl } from "@angular/forms";
import { locale as enLang } from "./i18n/en";
import { locale as bnLang } from "./i18n/bn";
import { Location } from "@angular/common";
import { AuthService } from "../../../../core/auth";

@Component({
	selector: "change-password",
	templateUrl: "./change-password.component.html",
	styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent {
	@Input() moduleName: string;
	model: ChangePasswordModel;
	roles: any;
	selectedRole: any;
	statuses: any;
	designations: any;
	officeId: number;
	userId: number;
	officeModel: OfficeModel;
	isEdit = false;
	_user: any;
	currentLanguage = "en";
	rolesFormControl = new FormControl();

	constructor(private userUtitlityService: UserUtilityService, private officeService: OfficeService, private translationService: TranslationService, private roleService: RoleService, private userService: UserService, private location: Location, private route: ActivatedRoute, public cd: ChangeDetectorRef, private authService: AuthService, private sanitizer: DomSanitizer) {
		this.model = new ChangePasswordModel();
		this.translationService.loadTranslations(enLang, bnLang);
	}
	notificationEnum: NotificationEnum;
	errorMessage: string;

	ngOnInit() {
		this.currentLanguage = this.translationService.getSelectedLanguage();
		this.statuses = this.userUtitlityService.getStatuses();
		this.userId = +this.route.snapshot.paramMap.get("userId");
		this.isEdit = this.userId > 0;

		var user = this.authService.getTokenData();
		this._user = {
			fullname: user.sub,
		};
	}

	onSave() {
		this.notificationEnum = NotificationEnum.None;

		this.userService.changePassword(this.model).subscribe(
			(data) => {
				this.notificationEnum = NotificationEnum.Update;
				setTimeout(() => {
					setTimeout(() => {
						this.goBack();
					});
				}, 2000);
				this.cd.detectChanges();
			},
			(error) => {
				this.notificationEnum = NotificationEnum.Error;
				this.errorMessage = error.error.error.errorInfo.validationResult.errors[0].errorMessage;
				this.cd.detectChanges();
			}
		);
	}

	goBack() {
		this.location.back();
	}

	hide: boolean = true;

	myFunction() {
		this.hide = !this.hide;
	}
}
