import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ApiResponseModel } from '../../model/shared/api-response.model';
import { AuthService } from '../../../core/auth';
import { DetailAssessmentPartB32EditModel} from '../../model';

@Injectable({
    providedIn: 'root'
})
export class DetailAssessmentFormPartB3BService {
    apiName = 'DetailAssessmentFormPartB3Bs';
    constructor(private httpClient: HttpClient, private authService: AuthService) {
    }
    saveDraft(model:DetailAssessmentPartB32EditModel) {
        return this.httpClient.post(environment.apiUrl + this.apiName+'/Drafts',model,this.authService.getRequestHeader());
    }
    updateDraft(id:number,model:DetailAssessmentPartB32EditModel) {
        return this.httpClient.put(environment.apiUrl + this.apiName+'/Drafts'+'/'+id,model,this.authService.getRequestHeader());
    }
    getDraftById(id:number){
        return this.httpClient.get<any>(environment.apiUrl + this.apiName+'/Drafts/'+id,this.authService.getRequestHeader())
    }
    saveForm(model:DetailAssessmentPartB32EditModel) {
        return this.httpClient.post(environment.apiUrl + this.apiName,model,this.authService.getRequestHeader());
    }
    updateForm(id:number,model:DetailAssessmentPartB32EditModel) {
        return this.httpClient.put(environment.apiUrl + this.apiName+'/'+id,model,this.authService.getRequestHeader());
    }
    getFormById(id:number){
        return this.httpClient.get<any>(environment.apiUrl + this.apiName+'/'+id,this.authService.getRequestHeader())
    }
    deleteDraft(id:number) {
        return this.httpClient.delete(environment.apiUrl + this.apiName+'/Drafts/'+id,this.authService.getRequestHeader());
    }
  
}