import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponseModel } from '../model/shared/api-response.model';
import { AuthService } from '../../core/auth';
import { MenuPermissionEditModel, MenuPermissionEditModels } from '../model';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    apiName = 'Menus';
    constructor(private httpClient: HttpClient, private authService: AuthService) {

    }
    getAll(pageNumber: number, pageSize: number) {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + '?Page=' + pageNumber + '&PageSize=' + pageSize, this.authService.getRequestHeader());
    }
    save(menuPermissions:MenuPermissionEditModels[]) {
		return this.httpClient.post<any>(environment.apiUrl + this.apiName + '/Permissions',{permissions:menuPermissions}, this.authService.getRequestHeader());
	}
	update(menuPermissions:MenuPermissionEditModels[]) {
		return this.httpClient.put<any>(environment.apiUrl + this.apiName + '/Permissions',{permissions:menuPermissions}, this.authService.getRequestHeader());
	}

    menuPermission = {
        convertToViewFormat: (menus: any, roles: any) => {
            console.log(menus);
            var menuPermissions = [];
            menus.forEach(element => {
                var permissions = [];
                roles.forEach(role => {
                    var permissionFound = element.menuPermissions.find(x => x.roleId === role.id);
                    permissions.push({
                        roleId: role.id,
                        isSelected: permissionFound ? true : false
                    })
                })
                menuPermissions.push({
                    id: element.id,
                    name: element.titleEn,
                    permissions: permissions
                })
            });
            return menuPermissions;
        },
        convertToEditModel: (menuPermissions: any): MenuPermissionEditModels[] => {
            if (!menuPermissions || menuPermissions.length === 0)
                return null;
            let menuPermissionEditModelTest:MenuPermissionEditModels[]=[];
            menuPermissions.forEach(item => {
                let roleIds: number[] = [];
                var selectedRoles = item.permissions.filter(x => x.isSelected);
                selectedRoles.forEach(role => {
                    roleIds.push(role.roleId);
                })
                var itemsToAdd = new MenuPermissionEditModels();
                itemsToAdd.menuId = item.id;
                itemsToAdd.roleIds = roleIds;
                menuPermissionEditModelTest.push(itemsToAdd);
            });
            return menuPermissionEditModelTest
        //     var menuPermissionEditModel = new MenuPermissionEditModel();
        //    // menuPermissionEditModel.permissions = menuPermissionEditModelTest;
        //     return menuPermissionEditModel;
        }
        // pushToFormPermissionsArray: (item: any, isAccess: boolean, accessCode: number, formPermissionEditModels: FormPermissionEditModels[]) => {
        //     if (isAccess) {
        //         var permission = new FormPermissionEditModels();
        //         permission.id = 0;
        //         permission.roleId = item.roleId;
        //         permission.roleName = item.roleName;
        //         permission.access = accessCode;
        //         formPermissionEditModels.push(permission);
        //     }
    }
}