import { Location } from "@angular/common";
import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { TranslationService } from "../../../../../core/_base/layout";
import { NotificationEnum, OfficeModel } from "../../../../model";
import { UserEditModel, UserOffice, UserRole } from "../../../../model/user/user-edit.model";
import { OfficeService, RoleService, UserService } from "../../../../services";
import { UserUtilityService } from "../../../../services/user-utility.service";
import { locale as bnLang } from "./i18n/bn";
import { locale as enLang } from "./i18n/en";

@Component({
	selector: "user-edit",
	templateUrl: "./user-edit.component.html",
	styleUrls: ["./user-edit.component.css"],
})
export class UserEditComponent {
	@Input() moduleName: string;
	@Input() userProfileId: string = "";
	model: UserEditModel;
	roles: any;
	selectedRole: any;
	statuses: any;
	designations: any;
	officeId: number;
	userId: number;
	officeModel: OfficeModel;
	isEdit = false;
	languageChangeSubscription: Subscription;
	currentLanguage = "en";
	rolesFormControl = new FormControl();
	officeNameReadOnly: string = "";

	constructor(private userUtitlityService: UserUtilityService, private officeService: OfficeService, private translationService: TranslationService, private roleService: RoleService, private userService: UserService, private location: Location, private route: ActivatedRoute, public cd: ChangeDetectorRef, private sanitizer: DomSanitizer) {
		this.model = new UserEditModel();
		this.translationService.loadTranslations(enLang, bnLang);
		this.currentLanguage = this.translationService.getSelectedLanguage();
	}
	notificationEnum: NotificationEnum;
	errorMessage: string;
	onSave() {
		var userRoles = [];
		this.selectedRole.forEach((item) => {
			var userRole = new UserRole();
			userRole.roleId = item;
			userRole.userId = this.isEdit ? this.userId : 0;
			userRoles.push(userRole);
		});
		var userOffices = [];
		var userOffice = new UserOffice();
		userOffice.userId = this.isEdit ? this.userId : 0;
		userOffice.officeId = this.officeId;
		userOffice.isMainOffice = true;
		//userOffice.isMainOffice
		userOffices.push(userOffice);
		this.model.userRoles = userRoles;
		this.model.userOffices = userOffices;
		this.notificationEnum = NotificationEnum.None;
		if (!this.isEdit) {
			this.userService.save(this.model, this.userImage, this.userSignature).subscribe(
				(data) => {
					this.notificationEnum = NotificationEnum.Add;
					this.cd.detectChanges();
				},
				(error) => {
					this.notificationEnum = NotificationEnum.Error;
					this.errorMessage = error.error.error.errorInfo.validationResult ? error.error.error.errorInfo.validationResult.errors[0].errorMessage : error.error.error.message;
					//this.errorMessage = error.error.error.errorInfo.validationResult.errors[0].errorMessage;
					this.cd.detectChanges();
				}
			);
		} else {
			this.userService.update(this.userId, this.model, this.userImage, this.userSignature).subscribe(
				(data) => {
					this.notificationEnum = NotificationEnum.Update;
					this.cd.detectChanges();
				},
				(error) => {
					this.notificationEnum = NotificationEnum.Error;
					this.errorMessage = error.error.error.errorInfo.validationResult ? error.error.error.errorInfo.validationResult.errors[0].errorMessage : error.error.error.message;
					//this.errorMessage = error.error.error.errorInfo.validationResult.errors[0].errorMessage;
					this.cd.detectChanges();
				}
			);
		}
	}

	ngOnInit() {
		this.languageChangeSubscription = this.translationService.getLangChangeEvent().subscribe((lang) => {
			this.currentLanguage = lang.lang;
			if (this.officeModel) {
				this.officeNameReadOnly = this.currentLanguage === "en" ? this.officeModel.nameEn : this.officeModel.nameBn;
			}
		});
		this.statuses = this.userUtitlityService.getStatuses();
		this.designations = this.userUtitlityService.getDesignation();
		this.officeId = +this.route.snapshot.paramMap.get("officeId");
		this.userId = this.userProfileId ? +this.userProfileId : +this.route.snapshot.paramMap.get("userId");
		this.isEdit = this.userId > 0;
		this.loadRoles();
		if (this.isEdit) {
			this.loadUsers();
		} else {
			this.getOfficeById();
		}
	}
	loadUsers() {
		this.userService.getById(this.userId).subscribe((item) => {
			this.model = item;
			this.model.confirmPassword = this.model.password;
			if (this.model.userRoles) {
				this.selectedRole = [];
				this.model.userRoles.forEach((item) => {
					this.selectedRole.push(item.roleId);
				});
			}
			this.cd.detectChanges();
			if (item.userOffices) {
				var office = item.userOffices.find((x) => x.isMainOffice === true);
				this.officeId = office.officeId;
				this.getOfficeById();
			}
			this.userImage = this.model["picture"];
			this.userSignature = this.model["signature"];
			this.userImageURL = this.loadFile(this.model["picture"]);
			this.userSignatureURL = this.loadFile(this.model["signature"]);
		});
	}
	getOfficeById() {
		this.officeService.getById(this.officeId).subscribe((data) => {
			this.officeModel = data;
			if (this.officeModel) {
				this.officeNameReadOnly = this.currentLanguage === "en" ? this.officeModel.nameEn : this.officeModel.nameBn;
			}
			this.cd.detectChanges();
		});
	}
	loadRoles() {
		this.roleService.getAll(1, 100).subscribe((data) => {
			this.roles = data.items;
			this.cd.detectChanges();
		});
	}
	get officeType() {
		if (!this.officeModel) return "";
		return this.currentLanguage === "en" ? this.officeModel.officeType.nameEn : this.officeModel.officeType.nameBn;
	}
	get officeName() {
		debugger;
		if (!this.officeModel) return "";
		return this.currentLanguage === "en" ? this.officeModel.nameEn : this.officeModel.nameBn;
	}
	loadFile(imageByte: any) {
		let objectURL = "data:image/png;base64," + imageByte;
		return this.sanitizer.bypassSecurityTrustUrl(objectURL);
	}
	userImage;
	userSignature;
	userImageURL: any;
	userSignatureURL: any;
	public message: string;

	preview(files) {
		if (files.length === 0) return;

		var mimeType = files[0].type;
		if (mimeType.match(/image\/*/) == null) {
			this.message = "Only images are supported.";
			return;
		}

		var reader = new FileReader();
		reader.readAsDataURL(files[0]);
		return reader;
	}
	previewSignature(files) {
		this.userSignature = files[0];
		var reader = this.preview(files);
		reader.onload = (_event) => {
			this.userSignatureURL = reader.result;
			this.cd.detectChanges();
		};
	}
	previewImage(files) {
		this.userImage = files[0];
		var reader = this.preview(files);
		reader.onload = (_event) => {
			this.userImageURL = reader.result;
			this.cd.detectChanges();
		};
	}
	goBack() {
		this.location.back();
	}

	ngOnDestroy() {
		this.languageChangeSubscription ? this.languageChangeSubscription.unsubscribe() : "";
	}
}
