import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiResponseModel } from '../model/shared/api-response.model';
import { AuthService } from '../../core/auth';


@Injectable({
    providedIn: 'root',
})
export class PendingTaskService {
    apiName = 'PendingTasks';
    constructor(private httpClient: HttpClient, private authService: AuthService) {
    }

    getTaskLists(primaryCaseTypeId, officeTypeId, officeId,userName, pageNumber: number, pageSize: number) {
        var query = '?Page=' + pageNumber + '&PageSize=' + pageSize
        if (primaryCaseTypeId > 0) {
            query = query + '&primaryCaseTypeId=' + primaryCaseTypeId
        }
        if (officeTypeId > 0) {
            query = query + '&officeTypeId=' + officeTypeId
        }
        if (officeId > 0) {
            query = query + '&officeId=' + officeId
        }
        if (userName!=='0') {
            query = query + '&userName=' + userName
        }
        return this.httpClient.get<any>(environment.apiUrl + this.apiName + query, this.authService.getRequestHeader());
    }

    getTaskHistory() {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + '/TaskHistory', this.authService.getRequestHeader());
    }
}