import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiResponseModel } from '../model/shared/api-response.model';
import { ChangePasswordModel, OfficeCatchmentEditModel, UserEditModel } from '../model';
import { AuthService } from '../../core/auth';


@Injectable({
    providedIn: 'root',
})
export class UserService {
    apiName = 'Users';
    constructor(private httpClient: HttpClient,private authService:AuthService) {

    }
    getUserByOfficeId(officeId, roleId) {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + 'Offices/'+officeId+'/users?roleId='+roleId+'&status=Active',this.authService.getRequestHeader());
    }
    getUserInfoByUserName(userName:string) {
        return this.httpClient.get<any>(environment.apiUrl + this.apiName+'/'+userName,this.authService.getRequestHeader());
    }
    getAll(pageNumber:number,pageSize:number,officeTypeId=0,officeId=0) {
        var search='?Page='+pageNumber+'&PageSize='+pageSize;
        if(officeTypeId && officeTypeId>0){
            search=search+'&officeTypeId='+officeTypeId;
        }
        if(officeId && officeId>0){
            search=search+'&officeId='+officeId;
        }
        return this.httpClient.get<any>(environment.apiUrl + this.apiName+search,this.authService.getRequestHeader());
    }
    getById(id) {
        return this.httpClient.get<any>(environment.apiUrl + this.apiName+'/'+id,this.authService.getRequestHeader());
    }
    getOfficeUsers(officeId:number,pageNumber:number,pageSize:number,checkAuthorization=true){
        var search='?Page='+pageNumber+'&PageSize='+pageSize+'&status='+'active&checkAuthorization='+checkAuthorization;
        return this.httpClient.get<any>(environment.apiUrl + 'Offices'+'/'+officeId+'/Users'+search,this.authService.getRequestHeader());
    }
    getByOfficeTypeId(officeTypeId) {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName+'?officeTypeId='+officeTypeId,this.authService.getRequestHeader());
    }
    save(model:UserEditModel,userImage,userSignature) {
        const formData = new FormData();
        formData.append('userImage', userImage);
        formData.append('userSignature', userSignature);
        formData.append('userRequestData', JSON.stringify(model));
    
        return this.httpClient.post(environment.apiUrl + this.apiName,formData,this.authService.getRequestHeader());
    }
    update(id:number,model:UserEditModel,userImage,userSignature) {
        const formData = new FormData();
        formData.append('userImage', userImage);
        formData.append('userSignature', userSignature);
        formData.append('updateUserRequestData', JSON.stringify(model));
        return this.httpClient.put(environment.apiUrl + this.apiName+'/'+id,formData,this.authService.getRequestHeader());
    }
    delete(id) {
        return this.httpClient.delete(environment.apiUrl + this.apiName+'/'+id,this.authService.getRequestHeader());
    }
    ActiveOrDeactive(id)
    {
        return this.httpClient.patch(environment.apiUrl + this.apiName+'/'+id,this.authService.getRequestHeader());
    }
    addCatchments(officeId:number,models:OfficeCatchmentEditModel[]){
        return this.httpClient.post(`${environment.apiUrl}${this.apiName}/${officeId}/Catchments`,{Catchments:models},this.authService.getRequestHeader());
    }

    //Change Password
    changePassword(model: ChangePasswordModel)
    {
        return this.httpClient.patch(environment.apiUrl + 'Users',model,this.authService.getRequestHeader());
    }

    addAdjacentOffices(userId:number,adjacentOffices:any){
        return this.httpClient.put(`${environment.apiUrl}${this.apiName}/${userId}/AdjacentOffices`,adjacentOffices,this.authService.getRequestHeader());
    }
}