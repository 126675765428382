export class CaseReintegrationEditModel{
    
    caseId:number;
    officeId: number; 
    reunificationDate:Date;
    address:string;  
    gdNumber:string;     
    initiatorComments:string;
  
   
}

export class CaseReintegrationApprovalModel{

    id: number;
    caseId:number;
    officeId: number; 
    supervisorComment: string;
    approvalStatus: number;
    childNameBn:string;
    childNameEn:string;
    registrationNo:string;
    officeNameEn:string;
    supervisor:string;
}

