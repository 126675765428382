// Angular
import { ChangeDetectorRef, Component } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from '@aspnet/signalr';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { environment } from '../../../../../environments/environment';
import { MessagingService } from '../../../../views/services/messaging.service';
import { NotificationService } from '../../../services';

import { Observable, Subscription } from 'rxjs';

@Component({
	selector: 'kt-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
	isNewMessage: boolean = false;
	hubConnection: HubConnection;
	subscription: Subscription;
	notificationCount: number = 0;
	_user: any;

	constructor(private authService: AuthService, public cd: ChangeDetectorRef, private notificationService: NotificationService) { }

	ngOnInit() {

		var user = this.authService.getTokenData();
		this._user = {
			currentUser: user.unique_name,
			mobilePhone: user.mobilephone
		}
		// this.subscription = this.messagingService.currentMessage.subscribe(
		// 	(message) => {
		// 		if(message) {
		// 			console.log("i am in the quick panel component");
		// 			console.log("There is a message " + message);
		// 			console.log(message);
		// 			this.isNewMessage=true;
		// 			this.cd.detectChanges();
		// 		}
		// 		else {
		// 			console.log("i am in the quick panel component");
		// 			console.log("No message Found");
		// 			console.log(message);
		// 		}
		// 	}
		//   );

		this.subscription = this.notificationService.currentNotification.subscribe((data: Observable<any>) => {
			data.subscribe((response) => {
				if (response) {
					if (response.items) {
						let count = response.items.filter(x => !x.isRead);
						if (count.length > 0) {
							this.notificationCount = count.length;
							this.isNewMessage = true;
							if (!this.cd['destroyed']) {
								this.cd.detectChanges();
							}

						}
					}
				}
			})
		});

		navigator.serviceWorker.addEventListener('message', (event) => {
			console.log("Message receive from service worker", event);
			if (event.data.data) {
				let response = event.data.data;
				console.log("response printing",response);
				console.log("logged in user",this._user.currentUser);
				console.log("event receiver",response.receiver);
				if (response.receiver == this._user.currentUser) {
					this.isNewMessage = true;
					console.log("current notification count", this.notificationCount);
					this.notificationCount += 1;
					console.log("Increasing notification count", this.notificationCount);
					this.notificationService.setLoaderVisibleStatus(true);
					if (!this.cd['destroyed']) {
						this.cd.detectChanges();
					}
				}
			}
		});
		// const options: IHttpConnectionOptions = {
		// 	accessTokenFactory: () => {
		// 	  return this.authService.token();
		// 	}
		// 	,transport: (HttpTransportType.LongPolling)
		//   };
		// this.hubConnection = new HubConnectionBuilder().withUrl(environment.notificationHub,options).build();
		// const self = this
		// this.hubConnection.start()
		// 	.then(() => {

		// 	})
		// 	.catch(err => console.log(err));

		// this.hubConnection.on('notify', () => {
		// 	this.isNewMessage=true;
		// 	this.cd.detectChanges();
		// })

	}
	changeBellIconColorAndReadData = () => {
		this.isNewMessage = false;
		if (this.notificationCount >= 0) {
			this.notificationService.markAllNotificationAsRead().subscribe((data) => {
				this.notificationCount = 0;
				// this.notificationService.getAll().subscribe((refreshedData) => {
				// 	console.log("Refreshing after reading data");
				// });
			});
		}
	}

	ngOnDestroy() {
		this.cd.detach();
		this.subscription.unsubscribe();
	}
}
