import { Injectable, EventEmitter, OnDestroy } from "@angular/core";
import { AppState } from "../core/reducers";
import { Store } from "@ngrx/store";
import { AuthService, Logout } from "../core/auth";

@Injectable({
	providedIn: "root",
})
export class InactivityService implements OnDestroy {
	private userActivity: EventEmitter<any> = new EventEmitter();
	private inactivityTimer: any;
	private timerConstant: any;

	constructor(private store: Store<AppState>, private authService: AuthService) {
		this.initListener();
		this.resetTimer();
		this.timerConstant = 30 * 60 * 1000; // 30 minutes in milliseconds
		// this.timerConstant = 5000; //5 Seconds for test purpose
	}

	resetTimer(): void {
		clearTimeout(this.inactivityTimer);
		this.inactivityTimer = setTimeout(() => {
			this.userActivity.emit(null);
		}, this.timerConstant);
	}

	logoutInactiveUser(): void {
		clearTimeout(this.inactivityTimer);
		this.inactivityTimer = setTimeout(() => {
			try {
				var user = this.authService.getTokenData();
				if (user) {
					this.userActivity.emit(null);
					this.store.dispatch(new Logout());
				}
			} catch (error) {
				this.userActivity.emit(null);
			}
		}, this.timerConstant);
	}

	onUserActivity(): EventEmitter<any> {
		return this.userActivity;
	}

	private initListener(): void {
		document.addEventListener("mousemove", () => this.resetTimer());
		document.addEventListener("keydown", () => this.resetTimer());
	}

	ngOnDestroy(): void {
		// Cleanup code when the component is unmounted
		clearTimeout(this.inactivityTimer);
		document.removeEventListener("mousemove", () => this.resetTimer());
		document.removeEventListener("keydown", () => this.resetTimer());
	}
}
