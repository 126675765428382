import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDialogApprovalComponent } from '../dialog/confirm-dialog-approval/confirm-dialog-approval.component';

@Injectable()
export class DialogApprovalService {
  constructor(public dialog: MatDialog, private router: Router) {
  }
  confirm(title: string, message: string,isConfirmation): Observable<boolean> {

    const dialogData = new ConfirmDialogModel(title, message,isConfirmation);
    const dialogRef = this.dialog.open(ConfirmDialogApprovalComponent, {
      maxWidth: "800px",
      data: dialogData,
      disableClose: false,
      closeOnNavigation: true
    });
    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });
    return dialogRef.afterClosed();
  }
}
