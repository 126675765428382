import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-draft-dialog',
  templateUrl: './draft-dialog.component.html',
  styleUrls: ['./draft-dialog.component.css']
})
export class DraftDialogComponent implements OnInit {
  title: string;
  message: string='';
  formName:string='';
  proceed:string = '';
  reject:string = '';
  isConfirmation:boolean
  subsription: any;

  constructor(public dialogRef: MatDialogRef<DraftDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DraftDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.formName=data.formName;
    this.proceed = data.proceed;
    this.reject = data.reject;
  }

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close({draftName:this.message,isConfirmed:true});
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close({draftName:this.message,isConfirmed:false});
  }
  ngOnDestroy() {
   
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class DraftDialogModel {

  constructor(public title: string,public formName:string, public message: string, public reject:string,public proceed: string ) {
  }
}

