// export class AddDraftDetailAssessmentFormPartB3ARequest {
//    detailAssessmentFormPartB3AData: string;
// }

export class DetailAssessmentPartB31EditModel {
   draftId:number;
   draftName: string;
   caseId:number;
   officeId:number;
   verifiedDate:string;
   isReferredOffice:boolean;
   sufferingChronicIllness:string;
   //whoAreSuffering:number[];
   //whoAreSufferingOthers:string;
   //diseaseNames:number[];
   //diseaseNameOthers:string;
   //sufferingPeriod:number;
   //sufferingPeriodOthers:string;
   familyDelinquencyHistory:string;
   //familyDelinquencyTypes:number[];
   parentsLiveTogether:string;
   separationPeriod:number;
   separationPeriodOthers:string;
   hasImprisonedParents:string;
   supervisedBy:number;
   supervisedByOthers:string;
   parentsLiveElsewhere:string;
   //liveElsewhereReasons:number[];
   //liveElsewhereReasonOthers:string;
   observation:string;
   


}



