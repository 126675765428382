
export class DetailAssessmentPartB17EditModel {
   draftId:number;
   draftName: string;
   caseId:number;
   officeId:number;
   verifiedDate:string;
   isReferredOffice:boolean;
   doParentsTakeToVisit:string;
   //doParentsContact:boolean;
   doesCareAboutFamilysIllness:string;
   doesParentsSeeAsDifferent:boolean;
   observation:string; 
   leisureTimeActivities:number[];
   leisureTimeActivityOthers:string;
   doesDeprived:string;
   doesUpsetAboutPersonOrSituation:string;
}



