export class AddFollowUpFormRequest {
   childSignature: File;
   gurdianSignature:File;
   probationOfficerSignature:File;
   followUpFormRequestData: string;
}


export class FollowupFormEditModel {
   caseId:number;
   officeId: number;
   followedOnDate: string;
   followedOn:string;
   supportDate: string;
   supportingAuthority: string;
   relevantInformation: string;
   childsView: string; 
   parentsOrProvidersOpinion: string;
   currentRiskScore: number;
   probationOfficerComment: string;
   recommendations: string;
   nextFollowUpDate: string;
   // childSignature: any;
   // gurdianSignature: any;
   // probationOfficerSignature: any;
   followUpMeasurements: MeasureTakenDetailsModel[];
   
   
}

export class MeasureTakenDetailsModel {

   measuresTaken: number;
   measuresTakenOthers: string;
   currentFollowUpStatus: string;

}

