import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth';

@Injectable({
	providedIn: 'root'
})
export class AuditLogService {

	pageModel = {
		currentPage: 1,
		pageSize: 20
	}

	constructor(private httpClient: HttpClient, private authService: AuthService) { }

	getAll<ApiResponseModel>(pageNumber: number, pageSize: number) {
		return this.httpClient.get<any>(environment.apiUrl + "Forms" + '?Page=' + pageNumber + '&PageSize=' + pageSize, this.authService.getRequestHeader());
	}
	getAllFormsByType<ApiResponseModel>(pageNumber: number, pageSize: number, formType: string) {
		return this.httpClient.get<any>(environment.apiUrl + "Forms" + '?formType=' + formType + '&Page=' + pageNumber + '&PageSize=' + pageSize, this.authService.getRequestHeader());
	}

	getFormUpdateHistoriesList<ApiResponseModel>(pageNumber: number, pageSize: number, dataViewModel:any) {
		return this.httpClient.get<any>(environment.apiUrl + "AuditTrails" + "/" +dataViewModel.formGroup+ '/'+dataViewModel.submissionId+'/Histories'+'?'+ 'Page=' + pageNumber + '&PageSize=' + pageSize, this.authService.getRequestHeader());
	}
	getFormUpdateHistoriesById<ApiResponseModel>(dataViewModel:any,id:number) {
		return this.httpClient.get<any>(environment.apiUrl + "AuditTrails" + "/" +dataViewModel.formGroup+ '/' + dataViewModel.submissionId+"/Histories/" + id, this.authService.getRequestHeader());
	}
	getFormUpdateHistoriesByIdOfParent<ApiResponseModel>(dataViewModel:any) {
		return this.httpClient.get<any>(environment.apiUrl + "AuditTrails" + "/" +dataViewModel.formGroup+ '/' + dataViewModel.submissionId, this.authService.getRequestHeader());
	}

	getAuditListByForms<ApiResponseModel>(pageNumber: number, pageSize: number,dataViewModel:any) {
		var query='Page=' + pageNumber + '&PageSize=' + pageSize;
		// if(dataViewModel.formGroup) {
		// 	query += '&formGroup='+ dataViewModel.formGroup;
		// }
		if(dataViewModel.submittedBy) {
			query += '&submittedBy='+ dataViewModel.submittedBy;
		}
		if(dataViewModel.dateFrom) {
			query +='&dateFrom='+ dataViewModel.dateFrom;
		}
		if(dataViewModel.dateTo) {
			query +='&dateTo='+ dataViewModel.dateTo;
		}
		if(dataViewModel.officeTypeId) {
			query += '&officeTypeId='+ dataViewModel.officeTypeId;
		}
		if(dataViewModel.officeId) {
			query += '&officeId='+ dataViewModel.officeId;
		}
		return this.httpClient.get<any>(environment.apiUrl + "AuditTrails" + "/"+ dataViewModel.formGroup +'?'+ query, this.authService.getRequestHeader());

	}

}
