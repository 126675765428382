
export class DetailAssessmentPartB15EditModel {
   draftId:number;
   draftName: string;
   caseId:number;
   officeId:number;
   verifiedDate:string;
   isReferredOffice:boolean;
   hasBeenAbused:string;
   torturedBy:number[];
   torturedByOthers:string;
   torturingTypes:number[];
   didReportToAnyone:string;
   hasGottenRemediation:string;
   observation:string; 
   torturedByExtendedFamilyOthers:string;
   torturingTypesOthers:string;
   childDelinquencyReason: string;
   lawSectionName: string;
   caseNumber: string;
   isChildConflictWithLaw: string;


}



