
export class DetailAssessmentPartB16EditModel {
   draftId:number;
   draftName: string;
   caseId:number;
   officeId:number;
   verifiedDate:string;
   doesFeelDifferentFromOthers:string;
   doesKnowRelativeNames:string;
   doesKnowHisBirthday:string;
   doesKnowParentsProfession:string;
   doesKnowParentsSocialStatus:string;
   observation:string; 
}



