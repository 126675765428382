import { Injectable } from "@angular/core";
import { ApiResponseModel } from "../model";

@Injectable({
    providedIn: 'root',
})
export class PaginationService {
    constructor(){
        
    }

    getLastPage(apiResponseModel:ApiResponseModel,pageSize:number,currentPage:number) {
		var record = 0;
		if (apiResponseModel) {
			record = pageSize* currentPage
			if (record > apiResponseModel.totalCount) {
				record = apiResponseModel.totalCount;
			}
		}
		return record;
	}
	getRowIndex=(index:number,pageSize:number,currentPage:number)=>index+1+((currentPage-1)*pageSize)
}