export class InterventionPlanEditModel{
    
    caseId:number;
    officeId: number; 
    //interventionPlan:number[];  
    //interventionPlanOthers:string 
    //implementer:string;
    //implementationPlanDate:Date;
    date:string;
    comments:string;
    nextReviewDate:string;
    implementationPlans : InterventionPlanDetailsModel[];
    attendedMembers : InterventionPlanMembersModel[];
    childsOpinions:string;
    parentsOrCaregiversOpinions:string;
    improvementKnowingWays:string;


}

export class InterventionPlanDetailsModel {

    interventionPlan : number;
    implementationProcess: string;
    implementationPlanDate:string;
    implementer:string;
    interventionPlanOthers:string;
}

export class InterventionPlanMembersModel {
    serialNo:number;
    name: string;
    designation: string;
}

