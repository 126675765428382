import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth';
import { ApiResponseModel, RoleEditModel } from '../model';
import { fadeInItems } from '@angular/material';

@Injectable({
    providedIn: 'root',
})
export class SettingService {

    apiName = 'Settings';
    constructor(private httpClient: HttpClient,private authService:AuthService) {

    }
    getByTypeName(typeName,pageNumber:number,pageSize:number) {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName +'?types=' + typeName+'&Page=' + pageNumber + '&PageSize=' + pageSize,this.authService.getRequestHeader());
    }
    genders=[
        {id:1, titleBn:'নারী',titleEn:'Female'},
        {id:2, titleBn:'পুরুষ',titleEn:'Male'},
        {id:3, titleBn:'হিজড়া',titleEn:'Third Gender'}
    ];
    getGenderById(id:number){
        return this.genders.find(x=>x.id===id);
    }

    primaryCaseTypes=[
        {id:1, titleBn:'আইনের সাথে সংঘাতে জড়িত শিশু',titleEn:'Child involved in conflict with the law'},
        {id:2, titleBn:'আইনের সংস্পর্শে আসা শিশু',titleEn:'Child in contact with the law'},
        {id:3, titleBn:'সুবিধাবঞ্চিত',titleEn:'Disadvantaged child'}
    ];

    getPrimaryCaseTypeById(id:number){
        return this.primaryCaseTypes.find(x=>x.id===id);
    }
    getDurationInDays(fromDate){
        let currentTime = new Date().getTime();
		let createdTime = new Date(fromDate).getTime();
        let difference = (currentTime - createdTime)
        return Math.round(difference/(1000*60*60*24));
    }
    getAge(createdDate: Date) {
		return this.getDurationInDays(createdDate);
    }
    getDurationInYears(date:Date){
        var days=this.getDurationInDays(date);
        return Math.round(days/365);
    }
    getTime(time: number) {
		let fraction = '';
		if (time < 1000) {
			return 1000 + ' ms';
		}
		time = time / 1000;
		if (time < 60)
			return time.toFixed(2) + ' s'

		time = time / 60

		if (time < 60) {
			fraction = time.toFixed(2)
			return fraction + ' minutes';
		}
		time = time / 60;
		if (time < 24)
			return time.toFixed(2) + ' h';
		
		time = time / 24;
		if (time < 30)
			return time.toFixed(2) + ' d';

		time = time / 30
		if (time < 12) {
			return time.toFixed(2) + ' months';
		}

		return time / 12 + ' years';

	}
    
}