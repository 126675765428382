export class CaseProfileModel {
    caseId: number;
    registrationNo: string;
    registrationDate: Date;
    officeId: number;
    officeNameEn: string;
    officeNameBn: string;
    birthRegistrationStatus: number;
    created: Date;
    aproxAge: number | null;
    birthDate: Date | null;
    gender: number | null;
    childNameBn: string;
    childNameEn: string;
    motherNameBn: string;
    fatherNameBn: string;
    guardianName: string;
    mobileNo: string;
    email: string;
    presentAddressLine: string;
    relation: string;
    riskScore: number;
    assignedTo: string;
    hasPriority: true;
    caseStatus: number;
    intakeFormId: string;
    riskAssessmentFormId: number;
    deailsFormPartAId: number;
    deailsFormPartB1AId: number;
    deailsFormPartB1BId: number;
    deailsFormPartB1CId: number;
    deailsFormPartB1DId: number;
    deailsFormPartB1EId: number;
    deailsFormPartB1FId: number;
    deailsFormPartB1GId: number;
    deailsFormPartB1HId: number;
    deailsFormPartB1IId: number;
    deailsFormPartB1JId:number;
    deailsFormPartB2AId: number;
    deailsFormPartB2BId: number;
    deailsFormPartB2CId: number;
    deailsFormPartB3AId: number;
    deailsFormPartB3BId: number;
    deailsFormPartB3CId: number;
    deailsFormPartB3DId: number;
    deailsFormPartCId: number;
    // interventionFormId: number;
    interventionActionFormId:number
    interventionPlanFormId:number;
    followupFormId: number;
    presentCityCorporation: number;
    presentDistrict: number;
    presentDivision: number;
    presentLocationType: number;
    presentThana: number;
    presentUnion: number;
    presentWard: number;
    genderDetail: any | null;
    childAge: number | null;
    intakeFormAge: number;
    actSectionName: string;
    caseNumber: string;
    involvedConflictWithLaw: boolean;
}