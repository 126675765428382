import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslationService } from '../../../core/_base/layout';
import { NavigationStart, Router } from '@angular/router';
import { locale as enLang } from '../welcome/i18n/en';
import { locale as bnLang } from '../welcome/i18n/bn';
import { filter } from 'rxjs/operators';

interface LanguageFlag {
	lang: string;
	name: string;
	flag: string;
	active?: boolean;
}



@Component({
  selector: 'kt-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  currentLanguage = 'en';
  language: LanguageFlag;
	languages: LanguageFlag[] = [
		{
			lang: 'bn',
			name: 'বাংলা',
			flag: './assets/media/flags/Bn.png'
		},
		{
			lang: 'en',
			name: 'English',
			flag: './assets/media/flags/En.png'
		}
		
	];

  constructor(private transalationService: TranslationService,private router: Router) {
    this.transalationService.loadTranslations(enLang, bnLang);
		this.currentLanguage = this.transalationService.getSelectedLanguage();
   }

  ngOnInit() {

    this.currentLanguage = this.transalationService.getSelectedLanguage();

		this.setSelectedLanguage();
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe(event => {
				this.setSelectedLanguage();
			});

  }

  setLanguage(lang) {
		this.languages.forEach((language: LanguageFlag) => {
			if (language.lang === lang) {
				language.active = true;
				this.language = language;
			} else {
				language.active = false;
			}
		});
		this.transalationService.setLanguage(lang);

	}

  setSelectedLanguage(): any {
		this.setLanguage(this.transalationService.getSelectedLanguage());
	}

}
