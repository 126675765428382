export class MenuConfig {
	public defaults: any = {
			aside: {
			self: {},
			items: [
				{
					id:1,
					key:'Dashboards',
					title: 'Dashboard',
					root: true,
					icon: 'flaticon2-architecture-and-city',
					page: '/dashboard',
					translate: 'MENU.DASHBOARD',
					bullet: 'dot',
				},
				{
					id:2,
					key:'IntakeForm',
					title: 'Intake Form',
					translate: 'MENU.INTAKEFORM.TITLE',
					root: true,
					icon: 'flaticon2-contract',
					bullet: 'dot',
					page: '/builder',
					submenu: [
						{
							key:'NewIntakeForm',
							title: 'New Intake Form',
							translate: 'MENU.INTAKEFORM.CHILD.NEW',
							page: '/intake-form/intake',
							icon: 'flaticon2-expand'
							// permission: 'accessToECommerceModule'
						},
						{
							key:'DraftIntakeForm',
							title: 'Draft Intake Form',
							icon: 'flaticon2-expand',
							page: '/intake-form/draft',
							translate: 'MENU.INTAKEFORM.CHILD.DRAFT',
						}
					]
				},
				{
					id:3,
					key:'PendingTask',
					title: 'Pending Task',
					root: true,
					icon: 'flaticon2-architecture-and-city',
					page: '/pending-task',
					translate: 'MENU.PENDINGTASK',
					bullet: 'dot',
				},
				{
					id:4,
					key:'CaseList',
					title: 'Case List',
					translate: 'MENU.CASE.TITLE',
					root: true,
					icon: 'flaticon2-expand',
					bullet: 'dot',
					page: '/builder',
					submenu: [

						{
							key:'MyCaseList',
							title: 'MY CASE LIST',
							translate: 'MENU.CASE.CHILD.MYCASELIST',
							page: '/case-management/cases/my-case-list',
							icon: 'flaticon2-expand',
							//permission: 'accessToECommerceModule'
						},
						{
							key:'MyInitiatedCaseList',
							title: 'Case Initiate List',
							icon: 'flaticon2-expand',
							page: '/case-management/cases/case-initiate',
							translate: 'MENU.CASEINITIATE.TITLE',
							bullet: 'dot',
						},
						{
							key:'PriorityCaseList',
							title: 'PRIORITY CASE LIST',
							icon: 'flaticon2-expand',
							page: '/case-management/cases/priority-case-list',
							translate: 'MENU.CASE.CHILD.PRIORITYCASELIST',
						},
						// {
						// 	key:'ReferredCasesToMe',
						// 	title: 'REFERED CASES TO ME',
						// 	icon: 'flaticon2-expand',
						// 	page: '/case-management/cases/refered-my-case-list',
						// 	translate: 'MENU.CASE.CHILD.REFEREDMYCASELIST',
						// },
						{
							key:'FindDuplicateCase',
							title: 'Find Duplicate Case',
							translate: 'MENU.CASE.CHILD.DUPLICATECASELIST',
							page: '/case-management/cases/duplicate-case-list',
							icon: 'flaticon2-expand',
							//permission: 'accessToECommerceModule'
						},
						{
							key:'ReferredFormToMe',
							title: 'REFERED FORMS TO ME',
							icon: 'flaticon2-expand',
							page: '/case-management/cases/refered-form-list-to-me',
							translate: 'MENU.CASE.CHILD.REFEREDFORMTOME',
						},
						{
							key:'ReferredCases',
							title: 'REFERED CASES',
							translate: 'MENU.CASE.CHILD.REFEREDCASELIST',
							page: '/case-management/cases/refered-case-list',
							icon: 'flaticon2-expand',
							//permission: 'accessToECommerceModule'
						},
						{
							key:'ReintegratedCases',
							title: 'REINTEGRATED CASES',
							icon: 'flaticon2-expand',
							page: '/case-management/cases/reintegrated-case-list',
							translate: 'MENU.CASE.CHILD.REINTEGRATEDCASELIST',
						},
						{
							key:'TransferCases',
							title: 'TRANSFER CASES',
							icon: 'flaticon2-expand',
							page: '/case-management/cases/transfer-case-list',
							translate: 'MENU.CASE.CHILD.TRANSFERCASELIST',
						},
						{
							key:'ReopenedCases',
							title: 'REOPENED CASES',
							translate: 'MENU.CASE.CHILD.REOPENEDCASELIST',
							page: '/case-management/cases/reopened-case-list',
							icon: 'flaticon2-expand',
							//permission: 'accessToECommerceModule'
						},
						{
							key:'ClosedCases',
							title: 'CLOSED CASES',
							icon: 'flaticon2-expand',
							page: '/case-management/cases/closed-case-list',
							translate: 'MENU.CASE.CHILD.CLOSEDCASELIST',
						},
						{
							key:'DropoutCases',
							title: 'DROPOUT CASES',
							icon: 'flaticon2-expand',
							page: '/case-management/cases/dropout-case-list',
							translate: 'MENU.CASE.CHILD.DROPOUTCASELIST',
						}
					]
				},
				{
					id:5,
					key:'RoleManagement',
					title: 'Role Management',
					root: true,
					icon: 'flaticon2-user-1',
					page: '/role-management/roles',
					translate: 'MENU.ROLEMANAGEMENT',
					bullet: 'dot',
				},
				{
					id:6,
					key:'OfficeManagement',
					title: 'Office Management',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-soft-icons-1',
					translate: 'MENU.OFFICEMANAGEMENT.TITLE',
					page: '/office-management/offices',
				},
				
				{
					id:7,
					key:'UserMangement',
					title: 'User Management',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-user-outline-symbol',
					translate: 'MENU.USERMANAGEMENT.TITLE',
					page: '/user-management/users',
					// submenu: [
					// 	{
					// 		title: 'Users',
					// 		page: '/user-management/users',
					// 		translate: 'MENU.USERMANAGEMENT.CHILD.USERS',
					// 	},
					// 	{
					// 		title: 'Roles',
					// 		page: '/user-management/roles',
					// 		translate: 'MENU.USERMANAGEMENT.CHILD.ROLES',
					// 	}
					// ]
				},
				{
					id:8,
					key:'FormsManagement',
					title: 'Forms',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-sheet',
					translate: 'MENU.FORMS.TITLE',
					page: '/forms/list',
				},
				{
					id:9,
					title: 'Reports',
					key:'Reports',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-line-chart',
					translate: 'MENU.REPORTS.TITLE',
					submenu: [
						{
							key:'SupportReport',
							title: 'Support Report',
							icon: 'flaticon2-expand',
							page: '/reports/support',
							translate: 'MENU.REPORTS.CHILD.SUPPORT',
						},
						{
							key:'MonthlyProgressReport',
							title: 'Monthly Progress Report',
							icon: 'flaticon2-expand',
							page: '/reports/progressReport',
							translate: 'MENU.REPORTS.CHILD.PROGRESSREPORT',
						}
					]
				},
				{
					id:10,
					key:'AccessControl',
					title: 'Access Control',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-lock',
					translate: 'MENU.ACCESSCONTROL.TITLE',
					page: '/access-control',
				},
				{
					id:11,
					title: 'App Module Access',
					root: true,
					bullet: 'dot',
					icon: 'flaticon-lock',
					translate: 'MENU.APPMODULEACCESS.TITLE',
					submenu: [
						
					]
				},
				{
					id:12,
					key:'Exports',
					title: 'Exports',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-download-2',
					translate: 'MENU.EXPORTS.TITLE',
					page: '/export',
				},
				{
					id:13,
					key:'AuditTrails',
					title: 'Audit Log',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-copy',
					translate: 'MENU.AUDITS.TITLE',
					page: '/audit-log',
				},
				{
					id:14,
					key:'IssueManagement',
					title: 'Issue Management',
					root: true,
					bullet: 'dot',
					icon: 'flaticon2-settings',
					translate: 'MENU.ISSUE.TITLE',
					submenu: [
						{
							key:'IssueDashboard',
							title: 'Issue Dashboard',
							icon: 'flaticon2-expand',
							page: '/issue-management/list',
							translate: 'MENU.ISSUE.CHILD.ISSUEDASHBOARD',
						},
						{
							key:'MyIssues',
							title: 'My Issues',
							icon: 'flaticon2-expand',
							page: '/issue-management/my-issues',
							translate: 'MENU.ISSUE.CHILD.MYISSUE'
						}
					]
				}
				// {
				// 	id:12,
				// 	key:'GeoLocationMangement',
				// 	title: 'GeoLocation Management',
				// 	root: true,
				// 	bullet: 'dot',
				// 	icon: 'flaticon2-user-outline-symbol',
				// 	translate: 'MENU.GEOLOCATION.TITLE',
				// 	submenu: [
						
				// 	]
				// },
				
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
