// USA
export const locale = {
	lang: 'bn',
	data: {
		USER: {
			LIST: {
				TITLE: 'User Management',
				BUTTON: {
					ADD: {CAPTION:'নতুন ব্যবহারকারী যুক্ত করুন'},
					VIEW:{TITLE:'ব্যবহারকারী দেখুন'},
					TRANSFER:{TITLE:'অফিস স্থানান্তর'},
                    ADJACENT:{TITLE:'ভারপ্রাপ্ত অফিস'},
				},
				TABLECAPTION: {
					HEADER: {
						USERNAME: 'ব্যবহারকারীর প্রাথমিক তথ্য',
						OFFICE: 'অফিসের নাম',
						ROLE: 'রোল'
					}
				}
			},
			DELETEDIALOGUE:{
				DIALOGUEHEADER: 'আপনি কি নিশ্চিন্ত যে এই ইউসার ডিলিট করতে চান? ',
				DIALOGUETITLE: 'আপনি এই ইউসার ডিলিট মুছে ফেলতে চলেছেন, এই পদ্ধতিটি অপরিবর্তনীয়।',
				CONFIRMATIONMESSAGE: 'আপনি কি এগিয়ে যেতে চান?',
				REJECT: 'বাতিল করুন',
				PROCEED: 'ঠিক আছে',
			},
			SUCCESS:{
				DELETE: 'ব্যবহারকারী সফলভাবে মুছে ফেলা হয়েছে.',
				ACTIVE: 'ব্যবহারকারী সক্রিয়/নিষ্ক্রিয় সফলভাবে।'
			}
		}
	}
};