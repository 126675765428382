import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponseModel, RoleEditModel } from '../model';
import { AuthService } from '../../core/auth';

@Injectable({
    providedIn: 'root'
})
export class RoleService {
    apiName = "Roles";
    constructor(private httpClient: HttpClient,private authService:AuthService) {

    }
    getAll(pageNumber:number,pageSize:number) {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName+'?Page='+pageNumber+'&PageSize='+pageSize,this.authService.getRequestHeader());
    }
    getById(id) {
        return this.httpClient.get<any>(environment.apiUrl + this.apiName+'/'+id,this.authService.getRequestHeader());
    }
    getByOfficeTypeId(officeTypeId) {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName+'?officeTypeId='+officeTypeId,this.authService.getRequestHeader());
    }
    save(model:RoleEditModel) {
        return this.httpClient.post(environment.apiUrl + this.apiName,model,this.authService.getRequestHeader());
    }
    update(id:number,model:RoleEditModel) {
        return this.httpClient.put(environment.apiUrl + this.apiName+'/'+id,model,this.authService.getRequestHeader());
    }
    delete(id) {
        return this.httpClient.delete(environment.apiUrl + this.apiName+'/'+id,this.authService.getRequestHeader());
    }
}