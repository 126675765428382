// USA
export const locale = {
	lang: 'en',
	data: {
		MENU: {
			DASHBOARD: 'My Dashbaord',
			PENDINGTASK: 'Pending Task List',
			INTAKEFORM: {
				TITLE: "Intake Form",
				CHILD: {
					NEW: "New Intake Form",
					DRAFT: "Draft Intake Form"
				}
			},
			CASE: {
				TITLE: "Case List",
				CHILD: {
					MYCASELIST: "My Case List",
					PRIORITYCASELIST: "Priority Case List",
					REFEREDMYCASELIST: "Referred Cases to me",
					REFEREDFORMTOME:'Refered Form To Me',
					REFEREDCASELIST: "Refered Cases",
					REINTEGRATEDCASELIST: "Reintegrated Cases",
					DUPLICATECASELIST: "Find Duplicate Case",
					TRANSFERCASELIST: "Transfer Cases",
					REOPENEDCASELIST: "Reopened Cases",
					CLOSEDCASELIST: "Closed Cases",
					DROPOUTCASELIST: "Dropout Cases",
				}
			},
			ROLEMANAGEMENT: 'Role Management',
			OFFICEMANAGEMENT: {
				TITLE: "Office Management",
				CHILD: {

				}
			},
			USERMANAGEMENT: {
				TITLE: "User Management",
				CHILD: {
					USERS: "Users",
					ROLES: "Roles",
				}
			},
			FORMS: {
				TITLE: "Forms",
				CHILD: {

				}
			},
			REPORTS: {
				TITLE: "Reports",
				CHILD: {
					SUPPORT: "Support Reports",
					PROGRESSREPORT:"Monthly Progress Report"
				}
			},
			ACCESSCONTROL: {
				TITLE: "Access Control",
				CHILD: {

				}
			},
			APPMODULEACCESS: {
				TITLE: "App Module Access",
				CHILD: {

				}
			},
			GEOLOCATION: {
				TITLE: "Geo Location Management",
				CHILD: {

				}
			},
			EXPORTS: {
				TITLE: "Exports",
				CHILD: {

				}
			},
			AUDITS: {
				TITLE: "Audit Log",
				CHILD: {

				}
			},
			ISSUE: {
				TITLE: "Issue Management",
				CHILD: {
					ISSUEDASHBOARD: "Issue Dashboard",
					MYISSUE: "My Issue"
				}
			},
			CASEINITIATE: {
				TITLE: "My Initiated Case List",
				CHILD: {

				}
			}
		},
		AUTH: {
			LOGIN: {
				SIGNIN: 'Sign In',
				BUTTON: 'Sign In',
			},
			INPUT: {
				USERNAME: 'User Name',
				PASSWORD: 'Password',
			},
			REQUIRED:{
				USERNAME:'User Name is required',
				PASSWORD:'Password is required',
			},
			INVALID_LOGIN:'Invalid user name or password'
		},
		COMMON: {
			BUTTON: {
				SHOW: 'Show',
				ENTRY: 'entries',
				SUBMIT: 'Submit',
				DRAFT: 'Draft',
				DELETE: 'Delete',
				EDIT: 'Edit',
				FILTER: 'Filter',
				VIEW: 'View',
				ACTIVEDEACTIVATE:'Activate/ Deactivate',
				PERMISSION: 'Permission',
				BACK: 'Back to List',
				GENERATEPDF: 'Generate PDF'
			},
			INPUT: {
				SEARCH: 'Search...'
			},
			PAGINATION:{
				SHOWING: 'Showing',
				ENTRY: 'Entries'
			},
			TABLE: {
				HEADER: {
					SL: 'SL',
					ACTION: {
						OPERATION: 'Operation',
						ACTION: 'Action'
					}
				},
				LOADER: {
					TITLE : 'Page is Loading. Please wait for a while.'
				}
			},
			DASHBOARD: {
				TOTALNOOFCASES: 'Total Number of Cases',
				BOYS:'Boys',
				GIRLS:'Girls',
				THIRDGENDER:'Third Gender',
				NOOFCASESCONFLICTWITHLAW: 'No of cases conflict with law',
				NOOFCASESCONTACTWITHLAW: 'No of cases in contact with law',
				NOOFCASESFORDISADVANTAGECHILD:'No of cases for disadvantage child,'
			}
		}
	}
};
