import { Component, OnInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { locale as enLang } from './i18n/en';
import { locale as bnLang } from './i18n/bn';
import { TranslationService } from '../../../../../core/_base/layout'
import { Observable, Subscription, of, Subject } from 'rxjs';
import { DialogService, DraftDialogService, OfficeService, PaginationService, UserService } from '../../../../services';
import { ApiResponseModel } from '../../../../model';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'user-list',
	templateUrl: './user-list.component.html',
	styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit, OnDestroy {
	@Input() moduleName: string;
	pageSize = 5;
	currentPage = 1;
	message$ = new Subject<any>();
	officeId: number;
	officeTypeId: number;
	userServiceSubscription: Subscription;
	apiResponseModel: ApiResponseModel;
	currentLanguage = 'en';
	languageSubscription: Subscription;
	officeChangeSub: Subscription;
	userSearchModel: {
		officeTypeId: 0,
		officeId: 0;
	}
	constructor(
		private userService: UserService,
		private officeService: OfficeService,
		private translationService: TranslationService,
		private router: Router,
		private paginationService: PaginationService,
		private dialogService: DialogService,
		private route: ActivatedRoute,
		private cd: ChangeDetectorRef) {
		this.translationService.loadTranslations(enLang, bnLang);
		this.currentLanguage = this.translationService.getSelectedLanguage();
	}
	ngOnInit() {
		this.languageSubscription = this.translationService.getLangChangeEvent().subscribe(lang => {
			this.currentLanguage = lang.lang;
		})
		if (this.moduleName !== 'office-management') {
			this.officeId = this.officeService.userSearchModel.officeId;
			this.officeTypeId = this.officeService.userSearchModel.officeTypeId;
		}
		this.loadUsers();
		if (this.moduleName !== 'office-management') {
			this.officeChangeSub = this.translationService.getOfficeChangEvent().subscribe(item => {
				this.officeId = item.officeId;
				this.officeTypeId = item.officeTypeId;
				this.loadUsers();
			})
		}
	}
	loadUsers() {
		if (this.moduleName === 'office-management') {
			this.officeId = +this.route.snapshot.paramMap.get("officeId");
			this.userServiceSubscription = this.userService.getOfficeUsers(this.officeId, this.currentPage, this.pageSize,false).subscribe(item => {
				this.apiResponseModel = item;

				this.cd.detectChanges();
			})
		}
		else {
			this.userServiceSubscription = this.userService.getAll(this.currentPage, this.pageSize, this.officeTypeId, this.officeId).subscribe(item => {
				this.apiResponseModel = item;
				this.cd.detectChanges();
			})

		}
	}
	officeNames = (lang: string, user: any) => {
		if (!user.userOffices || user.userOffices.length === 0) {
			return '';
		}
		if (lang === 'en') {
			return Array.prototype.map.call(user.userOffices, function (item) { return item.office.nameEn; }).join(", ")
		}
		else {
			return Array.prototype.map.call(user.userOffices, function (item) { return item.office.nameBn; }).join(", ")
		}
	}
	roleName = (user: any) => {
		if (!user.userRoles || user.userRoles.length === 0) {
			return '';
		}
		return Array.prototype.map.call(user.userRoles, function (item) { return item.role.name; }).join(", ")
	}
	navigateToUserEdit(item: any) {
		if (this.moduleName === 'office-management') {
			this.router.navigate(['/office-management/offices', this.officeId, 'users', 'edit', item.id]);
		}
		else {
			this.router.navigate(['/user-management/users', item.id, 'edit']);
		}
	}
	onDelete(userId) {
		this.dialogService.confirm(this.translationService.getTanslationInComponent('USER.DELETEDIALOGUE.DIALOGUEHEADER'), this.translationService.getTanslationInComponent('USER.DELETEDIALOGUE.DIALOGUETITLE'), true).subscribe((isConfirmed: Boolean) => {
			if (isConfirmed) {
				this.userService.delete(userId).subscribe(item => {
					this.message$.next({ data: this.translationService.getTanslationInComponent('USER.SUCCESS.DELETE'), isVisible: true });
					setTimeout(() => {
						this.message$.next({ data: '', isVisible: false });
					}, 2000);
					this.loadUsers();
				})
			}
		})

	}
	OnActivateOrDeactivate(userId) {
		this.userService.ActiveOrDeactive(userId).subscribe(item => {
			this.message$.next({ data: this.translationService.getTanslationInComponent('USER.SUCCESS.ACTIVE'), isVisible: true });
			setTimeout(() => {
				this.message$.next({ data: '', isVisible: false });
			}, 2000);
			this.loadUsers();
		})
	}
	onPageSizeChange() {
		this.loadUsers()
	}
	getLastPage = () => this.paginationService.getLastPage(this.apiResponseModel, this.pageSize, this.currentPage);
	getRowIndex = (rowIndex) => this.paginationService.getRowIndex(rowIndex, this.pageSize, this.currentPage);
	onPageChange(event) {
		this.loadUsers();
	}
	ngOnDestroy() {
		this.cd.detach();
		if (this.officeChangeSub) {
			this.officeChangeSub.unsubscribe();
		}
	}
}
