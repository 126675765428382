export class AddDraftDetailAssessmentFormPartB2CRequest {
   detailAssessmentFormPartB2CData: string;
}


export class DetailAssessmentPartB23EditModel {
   draftId:number;
   draftName: string;
   caseId:number;
   officeId:number;
   verifiedDate:string;
   isReferredOffice:boolean;
   provideInspiration:string;
   provideProperGuidance:string;
   // notProvidedReasons:number[];
   // notProvidedReasonOthers:string;
   hasMovementLimitation:number;  
   hasDiciplinaryRules:number;
   observation:string;
   


}



