import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../core/auth';
import { environment } from '../../../environments/environment';
import { ApiResponseModel } from '../model';

@Injectable({
  providedIn: 'root'
})
export class IssueTrackerService {
  apiName = 'Issues';
  constructor(private httpClient: HttpClient, private authService: AuthService) {
  }

  saveOrUpdateIssue(model:any,image:any) {
    const formData = new FormData();
    formData.append('AttachImage',image);
    formData.append('IssueRequestData', JSON.stringify(model));
    return this.httpClient.post(environment.apiUrl + 'Issues',formData,this.authService.getRequestHeader());
  }

  getMyIssues(pageNumber: number, pageSize: number,dataViewModel:any){
    var query='Page=' + pageNumber + '&PageSize=' + pageSize;
		if(dataViewModel.issueStatus) {
			query += '&issueStatus='+ dataViewModel.issueStatus;
		}
		if(dataViewModel.priority) {
			query += '&priority='+ dataViewModel.priority;
		}
		if(dataViewModel.dateFrom) {
			query +='&dateFrom='+ dataViewModel.dateFrom;
		}
		if(dataViewModel.dateTo) {
			query +='&dateTo='+ dataViewModel.dateTo;
		}
		if(dataViewModel.issueCategory) {
			query +='&issueCategory='+ dataViewModel.issueCategory;
		}
    return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + "/MyIssues" + '?' + query, this.authService.getRequestHeader());

  }

  getAllIssues(pageNumber: number, pageSize: number,dataViewModel:any){
    var query='Page=' + pageNumber + '&PageSize=' + pageSize;
		if(dataViewModel.issueStatus) {
			query += '&issueStatus='+ dataViewModel.issueStatus;
		}
		if(dataViewModel.priority) {
			query += '&priority='+ dataViewModel.priority;
		}
		if(dataViewModel.dateFrom) {
			query +='&dateFrom='+ dataViewModel.dateFrom;
		}
		if(dataViewModel.dateTo) {
			query +='&dateTo='+ dataViewModel.dateTo;
		}
    return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + "/AllIssues" + '?' + query, this.authService.getRequestHeader());
  }

  getById(id:number){
	return this.httpClient.get<any>(environment.apiUrl + this.apiName + "/" + id, this.authService.getRequestHeader());
  }

  getActivitiesById(id:number){
	return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + "/" + id+"/Activities", this.authService.getRequestHeader());
  }

  updateStatus(id:number,model) {
	var query='issueStatus='+ model.issueStatus;
	if(model.resolution) {
		query += '&resolution='+ model.resolution;
	}
	if(model.comment) {
		query += '&comment='+ model.comment;
	}
	return this.httpClient.patch<any>(environment.apiUrl + this.apiName + "/UpdateStatus/" + id + '?'+ query,{},this.authService.getRequestHeader());
  }

  updateAssignee(id:number,model) {
	var query='assignedTo='+ model.assignedTo;
	if(model.comment) {
		query += '&comment='+ model.comment;
	}
	return this.httpClient.patch<any>(environment.apiUrl + this.apiName + "/UpdateAssignee/" + id + '?'+ query,{},this.authService.getRequestHeader());
  }

  getAssigneeList(pageNumber: number, pageSize: number) {
	var query='Page=' + pageNumber + '&PageSize=' + pageSize;
	return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + "/Assignees?"+query, this.authService.getRequestHeader());

  }


  
}
