export class CaseReopenEditModel{
    
    caseId:number;
    officeId: number; 
    reOpenDate:Date;
    reason:string;     
    initiatorComments:string;
  
   
}

export class CaseReopenApprovalModel{
    id: number;
    caseId:number;
    officeId: number; 
    reason:string; 
    approvalStatus: number;
    childNameBn:string;
    childNameEn:string;
    registrationNo:string;
    officeNameEn:string;
    supervisorComment:string;

}

