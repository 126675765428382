import { Component } from "@angular/core";
import { locale as enLang } from './i18n/en';
import { locale as bnLang } from './i18n/bn';
import { TranslationService } from '../../../core/_base/layout'

@Component({
	selector:'user-management',
	templateUrl:'./user-management.component.html'
})
export class UserManagementComponent{
	constructor(private translationService:TranslationService) {
		this.translationService.loadTranslations(enLang, bnLang);
		
	}
}