import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth';
import { CaseRefferalApprovalModel, CaseRefferalEditModel, CaseTransferEditModel } from '../model';

@Injectable({providedIn:'root'})
export class CaseRefferralService{
    apiName = 'CaseReferralForms';
    constructor(private httpClient: HttpClient,private authService:AuthService) {

    }
    // case Refferal
    caseRefferalSave(model:CaseRefferalEditModel){
        return this.httpClient.post(`${environment.apiUrl}${'CaseReferralForms'}`,model,this.authService.getRequestHeader());
    }

    getCaseRefferalById(caseRefferalId) {
        return this.httpClient.get<any>(environment.apiUrl + 'CaseReferralForms' +'/'+caseRefferalId,this.authService.getRequestHeader());
    }

    caseRefferalApproval(caseRefferalId,model:CaseRefferalApprovalModel){
        return this.httpClient.patch(`${environment.apiUrl}${'CaseReferralForms'}/${caseRefferalId}/Approvals`,model,this.authService.getRequestHeader());
    }
}