import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { AnyUserEditComponent } from "./edit/any-user-edit.component";
import { UpdateProfileComponent } from "./update-profile/update-profile.component";
import { UserManagementComponent } from "./user-management.component";
import { UsersComponent } from "./users/users.component";

const routes: Routes = [
	{
		path: "",
		component: UserManagementComponent,
		children: [
			{
				path: "",
				pathMatch: "full",
				redirectTo: "users",
			},
			{
				path: "users",
				component: UsersComponent,
			},
			{
				path: "users/:userId/edit",
				component: AnyUserEditComponent,
			},
			{
				path: "change-password",
				component: ChangePasswordComponent,
			},
			{
				path: "profile-update",
				component: UpdateProfileComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class UserManagementRoutingModule {}
