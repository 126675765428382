
export class DetailAssessmentPartB13EditModel {
   draftId:number;
   draftName: string;
   caseId:number;
   officeId:number;
   verifiedDate:string;
   isReferredOffice:boolean;
   canExpressEmotions:string;
   //doesUnderstandWithGestures:string;
   doesListenWithAttention:string;
   doesRespondEnthusiastically:string;
   doesHesitateToSeekHelp:string;
   //hesitationReasons:number[];
   //hesitationReasonOthers:string;
   observation:string; 
}



