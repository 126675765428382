import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth';

@Injectable({providedIn:'root'})
export class WelcomeService{
    apiName = 'PublicPages';
    constructor(private httpClient: HttpClient,private authService:AuthService) {

    }
    
    getAllSummery() {
        return this.httpClient.get<any>(environment.apiUrl + this.apiName + '/Dashboards', this.authService.getRequestHeader())  
    }
    getSupport() {
        return this.httpClient.get<any>(environment.apiUrl + this.apiName + '/Supports', this.authService.getRequestHeader())
    }

    
}