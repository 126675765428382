import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiResponseModel } from '../model/shared/api-response.model';
import { AuthService } from '../../core/auth';
import { CaseAssignmentEditModel, InterventionPlanEditModel, InterventionActionEditModel, CaseNoteEditModel, CaseClosureEditModel, CaseClosureApprovalModel, CaseTransferEditModel, CaseDropoutEditModel, CaseReintegrationEditModel, CaseReopenEditModel, FollowupFormEditModel } from '../model';
import { CaseProfileModel } from '../model';

import { ActionForm, CaseListFormModel } from '../model/case/case.model';


@Injectable({
    providedIn: 'root',
})
export class CaseService {
    apiName = 'Cases';
    constructor(private httpClient: HttpClient, private authService: AuthService) {
    }
    getCaseProfile(caseId: number) {
        return this.httpClient.get<CaseProfileModel>(environment.apiUrl + this.apiName + '/' + caseId + '/CaseProfile', this.authService.getRequestHeader());
    }
    getCaseProfileById(caseId: number) {
        return this.httpClient.get<any>(environment.apiUrl + this.apiName + '/' + caseId + '/CaseProfile', this.authService.getRequestHeader());
    }
    caseAssignSave(caseId: number, assignCaseRequest: CaseAssignmentEditModel) {
        return this.httpClient.patch(`${environment.apiUrl}${this.apiName}/${caseId}/AssignCase`, assignCaseRequest, this.authService.getRequestHeader());
    }
    caseAssignUpdate(caseId: number, assignCaseRequest: CaseAssignmentEditModel) {
        return this.httpClient.patch(`${environment.apiUrl}${this.apiName}/${caseId}/AssignCase`, assignCaseRequest, this.authService.getRequestHeader());
    }
    // getCases(caseListType: string, pageNumber: number, pageSize: number) {
    //     return this.httpClient.get<ApiResponseModel>(environment.apiUrl + caseListType + '?Page=' + pageNumber + '&PageSize=' + pageSize, this.authService.getRequestHeader());
    // }

    getCases(caseListType: string,searchModel: any, pageNumber: number, pageSize: number) {
        var query='Page=' + pageNumber + '&PageSize=' + pageSize;
        if(searchModel.primaryCaseTypeId){
            query=query+'&primaryCaseTypeId='+searchModel.primaryCaseTypeId;
        }
        if(searchModel.officeTypeId){
            query=query+'&officeTypeId='+searchModel.officeTypeId;
        }
        if(searchModel.officeId){
            query=query+'&officeId='+searchModel.officeId;
        }
        if(searchModel.userName){
            query=query+'&userName='+searchModel.userName;
        }
        if(searchModel.caseStatus){
            query=query+'&caseStatus='+searchModel.caseStatus;
        }
        //console.log(environment.apiUrl + caseListType + '?' +query);
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + caseListType + '?' +query, this.authService.getRequestHeader());
    }

    getDuplicateCases(searchModel: any, pageNumber: number, pageSize: number) {
        var query='Page=' + pageNumber + '&PageSize=' + pageSize;
        if(searchModel.birthRegistrationNo){
            query=query+'&birthRegistrationNo='+searchModel.birthRegistrationNo;
        }
        if(searchModel.childName){
            query=query+'&childName='+searchModel.childName;
        }
        if(searchModel.motherName){
            query=query+'&motherName='+searchModel.motherName;
        }
        if(searchModel.motherNid){
            query=query+'&motherNid='+searchModel.motherNid;
        }
        if(searchModel.fatherName){
            query=query+'&fatherName='+searchModel.fatherName;
        }
        if(searchModel.fatherNid){
            query=query+'&fatherNid='+searchModel.fatherNid;
        }
        if(searchModel.mobileNo){
            query=query+'&mobileNo='+searchModel.mobileNo;
        }
        if(searchModel.ageFrom){
            query=query+'&ageFrom='+searchModel.ageFrom;
        }
        if(searchModel.ageTo){
            query=query+'&ageTo='+searchModel.ageTo;
        }
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName+'/FindDuplicateCases' + '?'+query, this.authService.getRequestHeader());
    }
    getAvailableForms(caseId: number, formType: string) {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + '/' + caseId + '/AvailableForms?formType=' + formType + '&Page=1&PageSize=200', this.authService.getRequestHeader());
    }

    getAvailableFormsForFormReffer(caseId: number, formType: string, isReferable: boolean = true) {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + '/' + caseId + '/AvailableForms?formType=' + formType + '&Page=1&PageSize=200'+ '&isReferable=' + isReferable, this.authService.getRequestHeader());
    }
    getDraftForms(caseId: number, formType: string) {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + '/' + caseId + '/DraftForms?formType=' + formType + '&Page=1&PageSize=200', this.authService.getRequestHeader());
    }
    getSubmissionHistories(caseId: number) {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + '/' + caseId + '/FormSubmissionHistories?Page=1&PageSize=200', this.authService.getRequestHeader());
    }

    caseNoteSave(caseId: number, model: CaseNoteEditModel) {
        return this.httpClient.post(`${environment.apiUrl}${this.apiName}/${caseId}/CaseNotes`, model, this.authService.getRequestHeader());
    }
    caseNoteUpdate(caseId: number, caseNoteId: number, model: CaseNoteEditModel) {
        return this.httpClient.put(`${environment.apiUrl}${this.apiName}/${caseId}/CaseNotes` + '/' + caseNoteId, model, this.authService.getRequestHeader());
    }

    getCaseNotesByCaseId(caseId: number, pageNumber: number, pageSize: number) {
        return this.httpClient.get<any>(environment.apiUrl + this.apiName + '/' + caseId + '/CaseNotes' + '?Page=' + pageNumber + '&PageSize=' + pageSize, this.authService.getRequestHeader());
    }

    getCaseNoteById(caseId, caseNoteId) {
        return this.httpClient.get<any>(environment.apiUrl + this.apiName + '/' + caseId + '/CaseNotes' + '/' + caseNoteId, this.authService.getRequestHeader());
    }

    deleteCaseNote(caseId, id) {
        return this.httpClient.delete(environment.apiUrl + this.apiName + '/' + caseId + '/CaseNotes' + '/' + id, this.authService.getRequestHeader());
    }

    //Case Closure
    caseClosureSave(model: CaseClosureEditModel) {
        return this.httpClient.post(`${environment.apiUrl}${'CaseClosureForms'}`, model, this.authService.getRequestHeader());
    }
    caseClosureUpdate(model: CaseClosureEditModel) {
        return this.httpClient.put(`${environment.apiUrl}${'CaseClosureForms'}`, model, this.authService.getRequestHeader());
    }

    getSubmissionHistoriesList(caseId: number, formType: string) {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + '/' + caseId + '/FormSubmissionHistories?formType=' + formType + '&Page=1&PageSize=200', this.authService.getRequestHeader());
    }

    getCaseClosureList(caseId: number) {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + 'CaseClosureForms' + '/' + caseId, this.authService.getRequestHeader());
    }

    getCaseClosureById(caseFormId) {
        return this.httpClient.get<any>(environment.apiUrl + 'CaseClosureForms' + '/' + caseFormId, this.authService.getRequestHeader());
    }

    caseClosureApprove(caseFormId, model: CaseClosureApprovalModel) {
        return this.httpClient.patch(`${environment.apiUrl}${'CaseClosureForms'}/${caseFormId}/Approvals`, model, this.authService.getRequestHeader());
    }



    //intervention Plan

    interventionPlanSave(caseId: number, model: InterventionPlanEditModel) {
        return this.httpClient.post(`${environment.apiUrl}${this.apiName}/${caseId}/InterventionPlans`, model, this.authService.getRequestHeader());
    }
    interventionPlanUpdate(caseId: number, interventionPlanId: number, model: InterventionPlanEditModel) {
        return this.httpClient.put(`${environment.apiUrl}${this.apiName}/${caseId}/InterventionPlans` + '/' + interventionPlanId, model, this.authService.getRequestHeader());
    }

    getInterventionPlan(caseId: number) {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + '/' + caseId + '/InterventionPlans?Page=1&PageSize=200', this.authService.getRequestHeader());
    }

    getInterventionPlanById(caseId, interventionPlanId) {
        return this.httpClient.get<any>(environment.apiUrl + this.apiName + '/' + caseId + '/InterventionPlans' + '/' + interventionPlanId, this.authService.getRequestHeader());
    }


    //intervention Action

    interventionActionSave(caseId: number, model: InterventionActionEditModel) {
        return this.httpClient.post(`${environment.apiUrl}${this.apiName}/${caseId}/InterventionActions`, model, this.authService.getRequestHeader());
    }
    interventionActionUpdate(caseId: number, interventionActionId: number, model: InterventionActionEditModel) {
        return this.httpClient.put(`${environment.apiUrl}${this.apiName}/${caseId}/InterventionActions` + '/' + interventionActionId, model, this.authService.getRequestHeader());
    }

    getInterventionActions(caseId: number) {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + '/' + caseId + '/InterventionActions?Page=1&PageSize=200', this.authService.getRequestHeader());
    }

    getInterventionActionById(caseId, interventionActionId) {
        return this.httpClient.get<any>(environment.apiUrl + this.apiName + '/' + caseId + '/InterventionActions' + '/' + interventionActionId, this.authService.getRequestHeader());
    }


    //Followuop Form
    followupSave(caseId: number, model: FollowupFormEditModel) {
        //const formData = new FormData();
        //formData.append('childSignature', model.childSignature);
        //formData.append('gurdianSignature', model.gurdianSignature);
        //formData.append('probationOfficerSignature', model.probationOfficerSignature);
        //model.childSignature = null; model.gurdianSignature = null; model.probationOfficerSignature = null;
        //formData.append('followUpFormRequestData', JSON.stringify(model));
        return this.httpClient.post(`${environment.apiUrl}${this.apiName}/${caseId}/FollowUpForms`,model, this.authService.getRequestHeader());
    }
    followupUpdate(caseId: number, followUpId: number, model: FollowupFormEditModel) {
        //const formData = new FormData();
        //formData.append('childSignature', model.childSignature);
        //formData.append('gurdianSignature', model.gurdianSignature);
        //formData.append('probationOfficerSignature', model.probationOfficerSignature);
        //model.childSignature = null; model.gurdianSignature = null; model.probationOfficerSignature = null;
        //formData.append('followUpFormRequestData', JSON.stringify(model));
        return this.httpClient.put(`${environment.apiUrl}${this.apiName}/${caseId}/FollowUpForms` + '/' + followUpId, model, this.authService.getRequestHeader());
    }

    getFollowupActions(caseId: number) {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + '/' + caseId + '/FollowUpForms?Page=1&PageSize=200', this.authService.getRequestHeader());
    }

    getInterventionFollowupById(caseId, interventionFollowupId) {
        return this.httpClient.get<any>(environment.apiUrl + this.apiName + '/' + caseId + '/FollowUpForms' + '/' + interventionFollowupId, this.authService.getRequestHeader());
    }

// Inervention (RiskAssessment Date List)

getRiskAssessmentDate(caseId: number) {
    return this.httpClient.get<ApiResponseModel>(environment.apiUrl +'RiskAssessmentForms?caseId=' + caseId, this.authService.getRequestHeader());
}

getInitiativeTaken(riskAssessmentId: number) {
    return this.httpClient.get<ApiResponseModel>(environment.apiUrl +'RiskAssessmentForms/' + riskAssessmentId, this.authService.getRequestHeader());
}

getDetailAssessmentDate(caseId: number) {
    return this.httpClient.get<ApiResponseModel>(environment.apiUrl +'DetailAssessmentFormPartCs?caseId=' + caseId, this.authService.getRequestHeader());
}
getDetailAssInstruction(detailAssessmentId: number) {
    return this.httpClient.get<ApiResponseModel>(environment.apiUrl +'DetailAssessmentFormPartCs/' + detailAssessmentId, this.authService.getRequestHeader());
}

 ACTION_CONTEXTMENU: ActionForm[] = [
	{ name: 'CaseTransferForm', titleEn: 'Case Transfer', titleBn: 'কেইস ট্রান্সফার', access: [], url: 'transfer', listType: ['my-case-list','reintegrated-case-list','refered-case-list','reopened-case-list'] },
	{ name: 'CaseRefferalForm', titleEn: 'Case Reffer', titleBn: 'কেইস রেফার', access: [], url: 'reffer', listType: ['my-case-list','reintegrated-case-list','refered-case-list','transfer-case-list','reopened-case-list'] },
	{ name: 'CaseReintegrationform', titleEn: 'Case Reintegrate', titleBn: 'কেইস পুনঃএকত্রীকরন', access: [], url: 'reintegrate', listType: ['my-case-list','refered-case-list','transfer-case-list','reopened-case-list'] },
	{ name: 'CaseClosureForm', titleEn: 'Case Closure', titleBn: 'কেইস ক্লোজার', access: [], url: 'closure', listType: ['my-case-list','reintegrated-case-list','refered-case-list','transfer-case-list','reopened-case-list','duplicate-case-list'] },
	{ name: 'CaseDropoutForm', titleEn: 'Case Dropout', titleBn: 'কেইস ড্রপআউট', access: [], url: 'dropout', listType: ['my-case-list','reintegrated-case-list','refered-case-list','transfer-case-list','reopened-case-list','duplicate-case-list'] },
	{ name: 'CaseReopen', titleEn: 'Case Reopen', titleBn: 'কেইস রিওপেন', access: [], url: 'reopen', listType: ['dropout-case-list','closed-case-list'] }
]
 CASE_CHILD_MENU: CaseListFormModel[] = [
    { url: "case-initiate", titleEn: 'Case Initiate List', titleBn: 'আমার ইনিশিয়েটেড কেস লিস্ট', detailTitleEn: 'Case Initiate List', detailTitleBn: 'আমার ইনিশিয়েটেড কেস লিস্ট', caseListType: 'Cases/CaseInitiate',actionForms:[] },
	{ url: "my-case-list", titleEn: 'My Case List', titleBn: 'আমার কেইসের তালিকা', detailTitleEn: 'Case List', detailTitleBn: 'Case List', caseListType: 'Cases/MyCases',actionForms:[] },
	{ url: "priority-case-list", titleEn: 'Priority Case List', titleBn: 'অগ্রাধিকার  কেইসের তালিকা', detailTitleEn: 'Priority Case List', detailTitleBn: 'Priority Case List', caseListType: 'Cases/PriorityCases',actionForms:[] },
	{ url: "refered-my-case-list", titleEn: 'My Referred Case List', titleBn: 'রেফার কেইসের তালিকা', detailTitleEn: 'My Referred Case List', detailTitleBn: 'My Referred Case List', caseListType: 'CaseReferralForms',actionForms:[] },
	{ url: "refered-case-list", titleEn: 'Referred Case List', titleBn: 'রেফার কেইসের তালিকা', detailTitleEn: 'Referred Case List', detailTitleBn: 'Referred Case List', caseListType: 'CaseReferralForms',actionForms:[] },
	{ url: "refered-form-list-to-me", titleEn: 'Referred Form List', titleBn: 'রেফার ফর্মের তালিকা', detailTitleEn: 'Referred Form List', detailTitleBn: 'Referred Form List', caseListType: 'FormReferralForms/FormsReferredToMe',actionForms:[] },
	{ url: "reintegrated-case-list", titleEn: 'ReIntegrated Case List', titleBn: 'রি ইন্ট্রিগ্রেড কেইসের তালিকা', detailTitleEn: 'Reintegrated Case List', detailTitleBn: 'Reintegrated Case List', caseListType: 'CaseReIntegrationForms',actionForms:[] },
	{ url: "transfer-case-list", titleEn: 'Transfer Case List', titleBn: 'ট্রান্সফার কেইসের তালিকা', detailTitleEn: 'Transafer Case List', detailTitleBn: 'Transfer Case List', caseListType: 'CaseTransferForms',actionForms:[] },
	{ url: "reopened-case-list", titleEn: 'Reopened Case List', titleBn: 'পুনরায় চালু হওয়া কেইসসমূহ', detailTitleEn: 'Reopened Case List', detailTitleBn: 'Reopened Case List', caseListType: 'CaseReOpenForms',actionForms:[] },
	{ url: "closed-case-list", titleEn: 'Closed Case List', titleBn: 'বন্ধ কেইসসমূহ', detailTitleEn: 'Closed Case List', detailTitleBn: 'Closed Case List', caseListType: 'Cases/ClosedCases',actionForms:[] },
	{ url: "dropout-case-list", titleEn: 'Dropout Case List', titleBn: 'ড্রপআউট কেইসসমূহ', detailTitleEn: 'DropOut Case List', detailTitleBn: 'DropOut Case List', caseListType: 'CaseDropoutForms',actionForms:[] },
    { url: "duplicate-case-list", titleEn: 'Find Duplicate', titleBn: 'ডুপ্লিকেট কেইস', detailTitleEn: 'DropOut Case List', detailTitleBn: 'Find Duplicate', caseListType: 'Find Duplicate',actionForms:[] },
];

}