// USA
export const locale = {
	lang: 'en',
	data: {
		USER: {
			CHANGEPASSWORD: {
				TITLE: 'Change Password',
				BUTTONCAPTION: {
					BACK: 'Back',
					SUBMIT: 'Submit'
				},
				NOTIFICATION: {
					UPDATE: "Password has been changed successfully !!",
					ERROR: 'ERROR: No data updated.'
				},
				CONTROLCAPTION: {
					
					USERNAME: {
						NAME: 'User Name',
					},
					FULLNAME: {
						NAME: 'Full Name',
					},
					LASTNAME: {
						NAME: 'Last Name',
						PLACEHOLDER: 'Enter Last Name'
					},
					OLDPASSWORD: {
						NAME: 'Old Password',
						PLACEHOLDER: 'Enter Old Password'
					},
					NEWPASSWORD: {
						NAME: 'New Password',
						PLACEHOLDER: 'Enter New Password'
					},
					CONFIRMPASSWORD: {
						NAME: 'Confirm Password',
						PLACEHOLDER: 'Enter Password'
					}
					
				}
			}
		}
	}
};