import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../core/auth";

@Injectable({
	providedIn: "root",
})
export class OfficeTypeService {
	apiName = "OfficeTypes";
	constructor(private httpClient: HttpClient, private authService: AuthService) {}

	getAll(assignedOfficeTypesOnly: boolean = false) {
		return this.httpClient.get<any>(environment.apiUrl + this.apiName + "?assignedOfficeTypesOnly=" + assignedOfficeTypesOnly + "&PageSize=500", this.authService.getRequestHeader());
	}

	getById(id) {
		return this.httpClient.get<any>(environment.apiUrl + this.apiName + "/" + id, this.authService.getRequestHeader());
	}
}
