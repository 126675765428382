
export class DetailAssessmentPartB14EditModel {
   draftId:number;
   draftName: string;
   caseId:number;
   officeId:number;
   verifiedDate:string;
   isReferredOffice:boolean;
   canSayFriendsNames:string;
   //spareTimeActivities:number[];
   //spareTimeActivityOthers:string;
   //refusalReasons:number[];
  // refusalReasonOthers:string;
   //isHappyOnFriendsSuccess:string;
   //activitiesOnFriendsSuccess:number[];
   //activitiesOnFriendsSuccessOthers:string;
   doesAssistHomeWork:string;
   //doesVisitOnFriendsIllness:string;
   //behaviorWithDifferentThought:number;
   //behaviorWithDifferentThoughtOthers:string;
   hasAnyAddictedFriend:string;
   hasConsumedIntoxication:string;
   //consumedIntoxications:number[];
   //consumedIntoxicationOthers:string;
   //totalYearsConsumed:number;
   //totalMonthsConsumed:number;
   //isCreative:string;
   //creativityTypes:number[];
   //creativityTypeOthers:string;
   //doesFeelDifferentFromOthers:string;
   observation:string; 
}



