
export class DetailAssessmentPartB12EditModel {
   draftName: string;
   draftId:number;
   caseId:number;
   officeId:number;
   verifiedDate:string;
   isReferredOffice:boolean;
   //isAvgHeightWeihgtOk:string;
   //noOfMealsDaily:number;
   //hasReceivedNutritiousFood:string;
   observation:string;
   doesResideInInstitution: string;
   dislikeOrganizationSystems:number[];
   dislikeOrganizationSystemOthers : string;
   isAnyDislikeReason:number;
   dislikeReasonOthers:string;
   doesFeelUpsetByStaff:string;
   doesFeelUpsetByCoresident: string;
   canAcceptResidentsAsFriend:string;





}



