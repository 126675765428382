// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';
import { AuthService } from '../../../auth';

@Injectable()
export class MenuAsideService {
	// Public properties
	menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	/**
	 * Service constructor
	 *
	 * @param menuConfigService: MenuConfigService
	 */
	constructor(private menuConfigService: MenuConfigService, private authService: AuthService) {
		this.loadMenu();
	}

	/**
	 * Load menu list
	 */
	loadMenu() {
		// get menu list
		const menuItems: any[] = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
		this.menuList$.next(menuItems);
		// var menuPermission = this.authService.getTokenData();
		// var permittedMenu = [];
		// if (menuPermission.menuPermissions && menuPermission.menuPermissions.length > 0) {
		// 	menuPermission.menuPermissions.forEach(item => {
		// 		var menu = menuItems.find(x => x.key === item);
		// 		if (menu) {
		// 			if(menu.submenu && menu.submenu.length>0)
		// 			{
		// 				var subMenu=menu.submenu;
		// 				menu.submenu=[];
		// 				subMenu.forEach(s => {
		// 					if(menuPermission.menuPermissions.find(item=>item===s.key))
		// 					{
		// 						menu.submenu.push(s);
		// 					}
		// 				});
		// 			}
		// 			permittedMenu.push(menu);
		// 		}
		// 	});
		// }
		// this.menuList$.next(permittedMenu);
	}
}
