
export class DetailAssessmentPartB18EditModel {
   draftId:number;
   draftName: string;
   caseId:number;
   officeId:number;
   verifiedDate:string;
   isReferredOffice:boolean;
   canDoOwnDailyTasks:string;
   //canNotDoOwnTaskReasons:number[];
   //canNotDoOwnTaskReasonOthers:string;
   canMakeDecisions:string;
   //canNotMakeDecisionReason:number;
   //canNotMakeDecisionReasonOthers:string;
   //doesWorkWithOthersHelp:boolean;
   observation:string; 
}



