import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../core/auth";

@Injectable({ providedIn: "root" })
export class ReportService {
	apiName = "Reports";
	constructor(private httpClient: HttpClient, private authService: AuthService) {}
	getDashboard(searchModel?: any) {
		var query = "/NewDashboards?";

		if (searchModel && searchModel.divisionId) {
			query += "&divisionId=" + searchModel.divisionId;
		}
		if (searchModel && searchModel.districtId) {
			query += "&districtId=" + searchModel.districtId;
		}
		if (searchModel && searchModel.upazillaCityId) {
			query += "&upazillaCityId=" + searchModel.upazillaCityId;
		}
		if (searchModel && searchModel.thanaMuniCantUnionId) {
			query += "&thanaMuniCantUnionId=" + searchModel.thanaMuniCantUnionId;
		}
		if (searchModel && searchModel.wardNo) {
			query += "&wardNo=" + searchModel.wardNo;
		}
		if (searchModel && searchModel.dateFrom) {
			query += "&dateFrom=" + searchModel.dateFrom;
		}
		if (searchModel && searchModel.dateTo) {
			query += "&dateTo=" + searchModel.dateTo;
		}
		if (searchModel && searchModel.dobFrom) {
			query += "&dobFrom=" + searchModel.dobFrom;
		}
		if (searchModel && searchModel.dobTo) {
			query += "&dobTo=" + searchModel.dobTo;
		}
		if (searchModel && searchModel.ageFrom) {
			query += "&ageFrom=" + searchModel.ageFrom;
		}
		if (searchModel && searchModel.ageTo) {
			query += "&ageTo=" + searchModel.ageTo;
		}
		if (searchModel && searchModel.gender) {
			query += "&gender=" + searchModel.gender;
		}

		return this.httpClient.get<any>(environment.apiUrl + this.apiName + query, this.authService.getRequestHeader());
	}

	getFormStageDashboards(searchModel?: any) {
		var query = "/FormStageDashboards?";

		if (searchModel && searchModel.divisionId) {
			query += "&divisionId=" + searchModel.divisionId;
		}
		if (searchModel && searchModel.districtId) {
			query += "&districtId=" + searchModel.districtId;
		}
		if (searchModel && searchModel.upazillaCityId) {
			query += "&upazillaCityId=" + searchModel.upazillaCityId;
		}
		if (searchModel && searchModel.thanaMuniCantUnionId) {
			query += "&thanaMuniCantUnionId=" + searchModel.thanaMuniCantUnionId;
		}
		if (searchModel && searchModel.wardNo) {
			query += "&wardNo=" + searchModel.wardNo;
		}
		if (searchModel && searchModel.dateFrom) {
			query += "&dateFrom=" + searchModel.dateFrom;
		}
		if (searchModel && searchModel.dateTo) {
			query += "&dateTo=" + searchModel.dateTo;
		}
		if (searchModel && searchModel.dobFrom) {
			query += "&dobFrom=" + searchModel.dobFrom;
		}
		if (searchModel && searchModel.dobTo) {
			query += "&dobTo=" + searchModel.dobTo;
		}
		if (searchModel && searchModel.ageFrom) {
			query += "&ageFrom=" + searchModel.ageFrom;
		}
		if (searchModel && searchModel.ageTo) {
			query += "&ageTo=" + searchModel.ageTo;
		}
		if (searchModel && searchModel.gender) {
			query += "&gender=" + searchModel.gender;
		}

		return this.httpClient.get<any>(environment.apiUrl + this.apiName + query, this.authService.getRequestHeader());
	}

	getLocationBasedDashboards(pageNumber: number, pageSize: number, searchModel?: any) {
		var query = "Page=" + pageNumber + "&PageSize=" + pageSize;

		if (searchModel && searchModel.divisionId) {
			query += "&divisionId=" + searchModel.divisionId;
		}
		if (searchModel && searchModel.districtId) {
			query += "&districtId=" + searchModel.districtId;
		}
		if (searchModel && searchModel.upazillaCityId) {
			query += "&upazillaCityId=" + searchModel.upazillaCityId;
		}
		if (searchModel && searchModel.thanaMuniCantUnionId) {
			query += "&thanaMuniCantUnionId=" + searchModel.thanaMuniCantUnionId;
		}
		if (searchModel && searchModel.wardNo) {
			query += "&wardNo=" + searchModel.wardNo;
		}
		if (searchModel && searchModel.dateFrom) {
			query += "&dateFrom=" + searchModel.dateFrom;
		}
		if (searchModel && searchModel.dateTo) {
			query += "&dateTo=" + searchModel.dateTo;
		}
		if (searchModel && searchModel.dobFrom) {
			query += "&dobFrom=" + searchModel.dobFrom;
		}
		if (searchModel && searchModel.dobTo) {
			query += "&dobTo=" + searchModel.dobTo;
		}
		if (searchModel && searchModel.ageFrom) {
			query += "&ageFrom=" + searchModel.ageFrom;
		}
		if (searchModel && searchModel.ageTo) {
			query += "&ageTo=" + searchModel.ageTo;
		}
		if (searchModel && searchModel.gender) {
			query += "&gender=" + searchModel.gender;
		}

		return this.httpClient.get<any>(environment.apiUrl + this.apiName + `/LocationBasedDashboards?${query}`, this.authService.getRequestHeader());
	}
	getMonthlyCaseRecordDashboards(pageNumber: number, pageSize: number, searchModel?: any) {
		var query = "Page=" + pageNumber + "&PageSize=" + pageSize;

		if (searchModel && searchModel.divisionId) {
			query += "&divisionId=" + searchModel.divisionId;
		}
		if (searchModel && searchModel.districtId) {
			query += "&districtId=" + searchModel.districtId;
		}
		if (searchModel && searchModel.upazillaCityId) {
			query += "&upazillaCityId=" + searchModel.upazillaCityId;
		}
		if (searchModel && searchModel.thanaMuniCantUnionId) {
			query += "&thanaMuniCantUnionId=" + searchModel.thanaMuniCantUnionId;
		}
		if (searchModel && searchModel.wardNo) {
			query += "&wardNo=" + searchModel.wardNo;
		}
		if (searchModel && searchModel.dateFrom) {
			query += "&dateFrom=" + searchModel.dateFrom;
		}
		if (searchModel && searchModel.dateTo) {
			query += "&dateTo=" + searchModel.dateTo;
		}
		if (searchModel && searchModel.dobFrom) {
			query += "&dobFrom=" + searchModel.dobFrom;
		}
		if (searchModel && searchModel.dobTo) {
			query += "&dobTo=" + searchModel.dobTo;
		}
		if (searchModel && searchModel.ageFrom) {
			query += "&ageFrom=" + searchModel.ageFrom;
		}
		if (searchModel && searchModel.ageTo) {
			query += "&ageTo=" + searchModel.ageTo;
		}
		if (searchModel && searchModel.gender) {
			query += "&gender=" + searchModel.gender;
		}

		return this.httpClient.get<any>(environment.apiUrl + this.apiName + `/MonthBasedDashboards?${query}`, this.authService.getRequestHeader());
	}

	getDailyCaseRecordDashboards(pageNumber: number, pageSize: number, searchModel?: any) {
		var query = "Page=" + pageNumber + "&PageSize=" + pageSize;

		if (searchModel && searchModel.divisionId) {
			query += "&divisionId=" + searchModel.divisionId;
		}
		if (searchModel && searchModel.districtId) {
			query += "&districtId=" + searchModel.districtId;
		}
		if (searchModel && searchModel.upazillaCityId) {
			query += "&upazillaCityId=" + searchModel.upazillaCityId;
		}
		if (searchModel && searchModel.thanaMuniCantUnionId) {
			query += "&thanaMuniCantUnionId=" + searchModel.thanaMuniCantUnionId;
		}
		if (searchModel && searchModel.wardNo) {
			query += "&wardNo=" + searchModel.wardNo;
		}
		if (searchModel && searchModel.dateFrom) {
			query += "&dateFrom=" + searchModel.dateFrom;
		}
		if (searchModel && searchModel.dateTo) {
			query += "&dateTo=" + searchModel.dateTo;
		}
		if (searchModel && searchModel.dobFrom) {
			query += "&dobFrom=" + searchModel.dobFrom;
		}
		if (searchModel && searchModel.dobTo) {
			query += "&dobTo=" + searchModel.dobTo;
		}
		if (searchModel && searchModel.ageFrom) {
			query += "&ageFrom=" + searchModel.ageFrom;
		}
		if (searchModel && searchModel.ageTo) {
			query += "&ageTo=" + searchModel.ageTo;
		}
		if (searchModel && searchModel.gender) {
			query += "&gender=" + searchModel.gender;
		}

		return this.httpClient.get<any>(environment.apiUrl + this.apiName + `/DayBasedDashboards?${query}`, this.authService.getRequestHeader());
	}
	getSupport(pageNumber: number, pageSize: number, dataViewModel: any) {
		var query = "Page=" + pageNumber + "&PageSize=" + pageSize;
		if (dataViewModel.primaryChildTypeId) {
			query += "&primaryChildTypeId=" + dataViewModel.primaryChildTypeId;
		}
		if (dataViewModel.childTypeId) {
			query += "&childTypeId=" + dataViewModel.childTypeId;
		}
		if (dataViewModel.officeId) {
			query += "&officeId=" + dataViewModel.officeId;
		}
		if (dataViewModel.officeTypeId) {
			query += "&officeTypeId=" + dataViewModel.officeTypeId;
		}
		if (dataViewModel.presentDistrict) {
			query += "&districtId=" + dataViewModel.presentDistrict;
		}
		if (dataViewModel.presentDivision) {
			query += "&divisionId=" + dataViewModel.presentDivision;
		}
		if (dataViewModel.presentUpazilla) {
			query += "&upazilaId=" + dataViewModel.presentUpazilla;
		}
		if (dataViewModel.dateFrom) {
			query += "&dateFrom=" + dataViewModel.dateFrom;
		}
		if (dataViewModel.dateTo) {
			query += "&dateTo=" + dataViewModel.dateTo;
		}

		return this.httpClient.get<any>(environment.apiUrl + "Reports/SupportReports" + "?" + query, this.authService.getRequestHeader());
	}

	getMonthlyProgressReport<ApiResponseModel>(pageNumber: number, pageSize: number, dataViewModel: any) {
		var query = "Page=" + pageNumber + "&PageSize=" + pageSize;
		if (dataViewModel.childTypes) {
			query += "&childTypeId=" + dataViewModel.childTypes;
		}
		if (dataViewModel.officeId) {
			query += "&officeId=" + dataViewModel.officeId;
		}
		if (dataViewModel.officeTypeId) {
			query += "&officeTypeId=" + dataViewModel.officeTypeId;
		}
		if (dataViewModel.presentDistrict) {
			query += "&districtId=" + dataViewModel.presentDistrict;
		}
		if (dataViewModel.presentDivision) {
			query += "&divisionId=" + dataViewModel.presentDivision;
		}
		if (dataViewModel.presentUpazilla) {
			query += "&upazilaId=" + dataViewModel.presentUpazilla;
		}
		if (dataViewModel.reportDate) {
			query += "&dateFrom=" + dataViewModel.reportDate;
		}
		if (dataViewModel.dateTo) {
			query += "&dateTo=" + dataViewModel.dateTo;
		}
		if (dataViewModel.submittedBy) {
			query += "&username=" + dataViewModel.submittedBy;
		}
		return this.httpClient.get<any>(environment.apiUrl + this.apiName + "/MonthlyProgressReports" + "?" + query, this.authService.getRequestHeader());
	}
}
