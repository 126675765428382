// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';
import {ErrorPageComponent} from './views/theme/content/error-page/error-page.component';
// Auth
import {AuthGuard} from './core/auth';
import { WelcomeComponent } from './views/pages/welcome/welcome.component';
import { PrivacyPolicyComponent } from './views/pages/privacy-policy/privacy-policy.component';

const routes: Routes = [
	{path: 'auth', loadChildren: () => import('app/views/pages/auth/auth.module').then(m => m.AuthModule)},
	{path: 'welcome', component:WelcomeComponent},
	{path:'privacy-policy',component : PrivacyPolicyComponent},

	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				loadChildren: () => import('app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
			},
			{
				path: 'user-management',
				loadChildren: () => import('app/views/pages/user-management/user-management.module').then(m => m.UserManagementModule),
			},
			{
				path: 'case-management',
				loadChildren: () => import('app/views/pages/case/case.module').then(m => m.CaseModule),
			},
			{
				path: 'role-management',
				loadChildren: () => import('app/views/pages/role-management/role-management.module').then(m => m.RoleManagementModule),
			},
			{
				path: 'office-management',
				loadChildren: () => import('app/views/pages/office-management/office-management.module').then(m => m.OfficeManagementModule),
			},
			{
				path: 'forms',
				loadChildren: () => import('app/views/pages/forms/form.module').then(m => m.FormModule),
			},
			{
				path: 'access-control',
				loadChildren: () => import('app/views/pages/access-control/access-control.module').then(m => m.AccessControlModule),
			},
			{
				path: 'intake-form',
				loadChildren: () => import('app/views/pages/intake-form/intake-form.module').then(m => m.IntakeFormModule),
			},
			{
				path: 'pending-task',
				loadChildren: () => import('app/views/pages/pending-task/pending-task.module').then(m => m.PendingTaskModule),
			},
			{
				path: 'reports',
				loadChildren: () => import('app/views/pages/report/report.module').then(m => m.ReportModule),
			},
			{
				path: 'export',
				loadChildren: () => import('app/views/pages/export/export.module').then(m=>m.ExportModule),
			},
			{
				path: 'audit-log',
				loadChildren: () => import('app/views/pages/audit-log/audit-log.module').then(m=>m.AuditLogModule),
			},
			{
				path: 'issue-management',
				loadChildren: () => import('app/views/pages/issue-tracker/issue-tracker.module').then(m=>m.IssueTrackerModule),
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden'				},
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: 'dashboard', pathMatch: 'full'},
			// {path: '**', redirectTo: 'dashboard', pathMatch: 'full'},
		],
	},

	// {path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
