// USA
export const locale = {
	lang: 'en',
	data: {
		USER: {
			LIST: {
				BUTTON: {
					ADD: {CAPTION:'Add User'},
					VIEW:{TITLE:'View User'},
					TRANSFER:{TITLE:'Transfer Office'},
					ADJACENT:{TITLE:'Adjacent Office'},
				},
				TABLECAPTION: {
					HEADER: {
						USERNAME: 'User Basic Information',
						OFFICE: 'Office Name',
						ROLE:'Roles'
					}
				}
			},
			DELETEDIALOGUE:{
				DIALOGUEHEADER: 'User Delete Confirmation?',
				DIALOGUETITLE: 'You are about to delete this user, this procedure is irreversible.',
				CONFIRMATIONMESSAGE: 'আপনি কি এগিয়ে যেতে চান?',
				REJECT: 'বাতিল করুন',
				PROCEED: 'ঠিক আছে',
			},
			SUCCESS:{
				DELETE: 'User Deleted Successfully.',
				ACTIVE: 'User Activate/Deactivate Successfully.'
			}
			
		}
	}
};