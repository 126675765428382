
export class DetailAssessmentPartAEditModel {
   draftName: string;
   draftId: number;
   caseId: number;
   officeId: number;
   verifiedDate: string;
   isReferredOffice: boolean;
   isSendByChildWelfare: string;
   memorialNumber: number;
   memorialDate: string;
   districtUpazillaName: string;
   sendByChildWelfareReason: string;
   isCourtOrdered: string;
   courtName: string;
   caseNumber: number;
   lawSectionName: string;
   courtOrderSummary: string;
   isSendByPolice: string;
   gdNumber: string;
   gdDate: string;
   policeStationName: string;
   policeStationDistrict: number;
   sendByPoliceReason: string;
   homeType: number;
   homeTypeOthers: string;
   homeOwnership: number;
   homeOwnershipOthers: string;
   environment: number[];
   environmentOthers: string;
   wasChildInInstitution: string;
   isActionTakenByPolice: string;
   actionTakenByPolice:number;
   institutionName: string;
   institutionAddress: string;
   institutionPhoneNo: string;
   institutionType: number;
   govtInstitutionOthers: string;
   privateInstitutionName: string;
   familyDetails:DetailAssessmentPartAFamilyDetails[];
   receivedServices:DetailAssessmentPartAServiceModel[];
   childRisks:DetailAssessmentPartAChildRisks[];

}
export class DetailAssessmentPartAFamilyDetails {
   relativeName: string;
   relativeAge: number;
   relativeGender: number;
   relativeOccupation: string;
   relation: string;
   doesLiveWithChild: boolean;
   doesInvolveLawConflict: boolean;
   relativeChildType: number;
   organization: string
}

export class DetailAssessmentPartAServiceModel {
   descriptions: string;
   durations:number;
   reasons:string;
   comments:string;
}

export class DetailAssessmentPartAChildRisks {
   serialNo:number;
   risks:string;
}



