// USA
export const locale = {
	lang: "en",
	data: {
		WEBSITE: {
			LOGIN: "LOGIN",
			PUBLICPORTAL: "PORTAL",
			TOTAL: "Total",
			MALE: "Male",
			FEMALE: "Female",
			OTHERGENDER: "Other Gender",
			VISION: " Vision",
			MISSION: "Mission",
			REPORT: "Report",
			HOTLINE: "Hotline",
			CHILDLAW: "CHild Law",
			BANGLADESHCHILDPOLICY: "Bangladesh Child Policy",
			// CHILDACT:'Child Act',
			TITLE: "Welcome to Child Protection Case Management System",
			OCMSPURPOSE: "Child Protection Case Management System is a national database system for alternative care, residential care and community-based services where child information is stored, screened, assessed & tracked. The Objective of OCMS is to",
			OBJECTIVE1: "To track, monitor & manage child centrally",
			OBJECTIVE2: "To get timely information, data and reports",
			OBJECTIVE3: "See status and progress of a case instantly",
			OBJECTIVE4: "Standardize all work, forms and tasks for all",
			SUMMARY1: "Total Number of Cases",
			SUMMARY2: "Number of cases in conflict with law",
			SUMMARY3: "Number of cases in contact with law",
			SUMMARY4: "Number of cases for disadvantage child",
			SUMMARY5: "Total case for children with disabilities in case management",
			VISIONDETAILS: "Ensuring protection of children and creating enabling environment for the children through case management services by the qualified social workers in Bangladesh.",
			MISSIONDETAILS: "Functioning an online case management system that is easy to use by social work professionals to help children and their families in need of care and protection.",
			SUMMERYHEADING: "Case Management Summary",
			TOTALNUMBEROFCASES: "Total Number of Cases",
			CASESOFCONFLICTWITHLAW: "Number of cases in conflict with law",
			CASESOFCONTACTWITHLAW: "Number of cases in contact with law",
			CASESOFDISADVANTAGE: "Number of cases for disadvantage child",
			CASESOFDISABLITIES: "Total case for children with disabilities in case management",
			SERVICEWISEBREAKDOWN: "Service wise breakdown for child",
			SERVICETITLE: "SERVICE WISE",
			SERVICES: "Service wise breakdown for child",

			CHILDACT: "Children ACT  2013",
			CHILDACT2013: "The Children Act, 2013 (Act No. XXIV of 2013)",
			CHILDACTDETAILS: "The Children Act, 2013 , to provide for a new law for the purpose of implementing the United Nations Convention on the rights of the child by repealing the existing children act. WHEREAS Bangladesh has become a party to the United Nations Convention on the Rights of the Child, and WHEREAS it is expedient and necessary to provide for a new law to consolidate and reenact the existing Children Act by repealing it for the purpose of implementing the provisions of the said Convention.",
			CHILDACTDETAILS1: "The enactment of Children Act 2013 is considered to be a milestone action by the government to ensure rights and protection of children all across the country and implementation of this Act will strengthen the child protection system in the country.",
			CRC: "United Nations Convention on the Rights of the Child (UNCRC)",
			CONVENTIONOFRIGHTS: "In 1989, the world leaders made a historic commitment to the world’s children by adopting the United Nations Convention on the Rights of the Child (UNCRC). It’s become the most widely ratified human rights treaty in history and has helped transform children’s lives around the world.",
			CONVENTION1: "Government of Bangladesh ratified the UNCRC in 1990 as a commitment to fulfill the rights of children in Bangladesh.",
			CONVENTIONDETAILS: "Government submits the UNCRC periodic report to UN Committee on the Rights of the Child every 5 years to demonstrate the progress of implementation in line with UNCRC commitments.",
			CHILDPOLICY: "The National Children Policy 2011",
			CHILDPOLICYDETAILS: "The National Children Policy, applicable to all children without any discrimination, was endorsed in 2011. Contextual importance of the National Children Policy 2011 will be given into due consideration in making all national development policies, planning, program implementation and budgeting.The fundamental Principles of the National Children Policy are 1) Ensuring child rights in the light of the constitution of Bangladesh, Child Act and International Charters/ Conventions, 2) Poverty alleviation of the children, 3) Elimination of all forms of child abuse and discrimination, 4) Elimination of all forms of abuse of and discrimination to female child and 5) Participation of the children and accepting their views into consideration in overall protection and, in the best interest of the children.",
			CHILDAMENDMENT: "Children Amendment Act 2018",
			CHILDAMENDMENTDETAILS: "Children Act 1974 was formulated to ensure welfare, protection and rights of children for making them suitable for building up the nation, it was said in the bill adding that Children Act 2013 was formulated making Children Act 1974 time-befitting. The Children (Amendment) Bill 2018 was placed in parliament for bringing some amendments to the existing law for creating some problems of application of the law, the bill said. In the proposed amendment the term ‘children court’ in the existing law would be replaced by ‘children court or magistrate court or any other court’. Under the proposed law, the cases filed in children court comprising of additional session judge were not ready for trial would be transferred to the magistrate court concerned. According to the proposed law, the process of transfer of the cases from the court concerned would have to complete within 90 working days after proposed Children (Amendment) Act 2018 was put into effect. If not possible to transfer the cases within the stipulated time the session judge concerned could increase the deadline 45 days more.",
			DEVELOPMNETPARTNER: "DEVELOPMENT PARTNERS",
		},
	},
};
