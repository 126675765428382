export class AddDraftDetailAssessmentFormPartB3CRequest {
   detailAssessmentFormPartB3CData: string;
}


export class DetailAssessmentPartB33EditModel {
   draftId:number;
   draftName: string;
   caseId:number;
   officeId:number;
   verifiedDate:string;
   isReferredOffice:boolean;
   //hasHouseholdFacilities:number;
   isThereSeparateRoom:number;
   earner:number[];
   earnerOthers:string;
   //doAbledMembersWork:number;  
   //incomeSource:number[];
  // incomeSourceOthers:string;
   //annualIncome:number; 
  // annualIncomeOthers:number; 
   observation:string;
   


}



