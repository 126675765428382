import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponseModel } from '../model/shared/api-response.model';
import { AuthService } from '../../core/auth';
import { RiskAssesmentEditModel,IntakeFormEditModel } from '../model';


@Injectable({
    providedIn: 'root'
})
export class RiskAssessmentFormService {
    apiName = 'RiskAssessmentForms';
    constructor(private httpClient: HttpClient, private authService: AuthService) {

    }
    
    getDrafts(pageNumber: number, pageSize: number) {
        return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + '/Drafts?Page=' + pageNumber + '&PageSize=' + pageSize, this.authService.getRequestHeader());
    }
    getDraftById(id:number){
        return this.httpClient.get<any>(environment.apiUrl + this.apiName+'/Drafts/'+id,this.authService.getRequestHeader())
    }
    saveDraft(model:RiskAssesmentEditModel) {
        console.log(model);
        return this.httpClient.post(environment.apiUrl + this.apiName+'/Drafts',model,this.authService.getRequestHeader());
    }
    updateDraft(id:number,model:RiskAssesmentEditModel) {
        return this.httpClient.put(environment.apiUrl + this.apiName+'/Drafts/'+id,model,this.authService.getRequestHeader());
    }
    saveForm(model:RiskAssesmentEditModel){
        return this.httpClient.post(environment.apiUrl + this.apiName,model,this.authService.getRequestHeader());
    }
    updatForm(id:number,model:RiskAssesmentEditModel) {
        return this.httpClient.put(environment.apiUrl + this.apiName+'/'+id,model,this.authService.getRequestHeader());
    }
    getFormById(id:number){
        return this.httpClient.get(environment.apiUrl + this.apiName+'/'+id,this.authService.getRequestHeader());
    }

    deleteDraft(id:number) {
        return this.httpClient.delete(environment.apiUrl + this.apiName+'/Drafts/'+id,this.authService.getRequestHeader());
    }
}