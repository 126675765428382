// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslationService } from '../../../core/_base/layout';
import { NavigationStart, Router } from '@angular/router';
import { locale as enLang } from './i18n/en';
import { locale as bnLang } from './i18n/bn';
import { filter } from 'rxjs/operators';
import { WelcomeService } from '../../services';
import { WelcomeModel } from '../../model';
import { ApiResponseModel } from '../../model/shared/api-response.model';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';



interface LanguageFlag {
	lang: string;
	name: string;
	flag: string;
	active?: boolean;
}


@Component({
	selector: 'welcome',
	templateUrl: './welcome.component.html',
	styleUrls: ['./welcome.component.css'],
})
export class WelcomeComponent{

	title: string;
	currentLanguage = 'en';
	services:any;
	reportSummery: any;
	model: WelcomeModel;
	serviceSummary: any;
	serviceName: string;
	apiResponseModel: ApiResponseModel;
	totalMale: any;
	totalFemale: any;
	totalOtherGender: any;
	serviceBn: any;
	serviceEn: any;
	@ViewChild('carousel', {static : true}) carousel: NgbCarousel;

	language: LanguageFlag;
	languages: LanguageFlag[] = [
		{
			lang: 'bn',
			name: 'বাংলা',
			flag: './assets/media/flags/Bn.png'
		},
		{
			lang: 'en',
			name: 'English',
			flag: './assets/media/flags/En.png'
		}
		
	];

	/**
	 * Component constructor
	 *
	 * @param translationService: TranslationService
	 * @param router: Router
	 */
	 
	 carouselItems=[
		 {caption:'শিশু সুরক্ষায় কেইস ম্যানেজমেন্ট',imagePath:'./assets/media/images/home/slide2.jpg'},
		 {caption:'শিশু সুরক্ষায় কেইস ম্যানেজমেন্ট',imagePath:'./assets/media/images/home/slide1.jpg'},
		 {caption:'শিশু সুরক্ষায় কেইস ম্যানেজমেন্ট',imagePath:'./assets/media/images/home/slide3.jpg'},
		 {caption:'শিশু সুরক্ষায় কেইস ম্যানেজমেন্ট',imagePath:'./assets/media/images/home/slide4.jpg'},
	 ]

	constructor(		
		//private translationSsdfeervice: TranslationService,
		private welcomeService: WelcomeService,
		private transalationService: TranslationService,
		// private location: Location,		
		public cd: ChangeDetectorRef,
		private router: Router) {
		
		this.transalationService.loadTranslations(enLang, bnLang);
		this.currentLanguage = this.transalationService.getSelectedLanguage();
	}
	
	
	ngOnInit() {
		// this.carousel.cycle();
		this.currentLanguage = this.transalationService.getSelectedLanguage();

		this.setSelectedLanguage();
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe(event => {
				this.setSelectedLanguage();
			});
		
		this.loadReportSummery();

		this.welcomeService.getAllSummery().subscribe(item => {
				this.model = {
					totalMale: this.calculateTotal(item, 'totalMale'),
					totalFemale: this.calculateTotal(item, 'totalFemale'),
					totalOtherGender: this.calculateTotal(item, 'totalOtherGender'),
					totalExposedToTheLawMale: this.calculateTotal(item, 'exposedToTheLawMale'),
					totalExposedToTheLawFemale: this.calculateTotal(item, 'exposedToTheLawFemale'),
					totalExposedToTheLawOtherGender: this.calculateTotal(item, 'exposedToTheLawOtherGender'),
					totalConflictWithLawMale: this.calculateTotal(item, 'conflictWithLawMale'),
					totalConflictWithLawFemale: this.calculateTotal(item, 'conflictWithLawFemale'),
					totaConflictWithLawOtherGender:this.calculateTotal(item, 'conflictWithLawOtherGender'),
					totalDisadvantagedMale: this.calculateTotal(item, 'disadvantagedMale'),
					totalDisadvantagedFemale: this.calculateTotal(item, 'disadvantagedFemale'),
					totalDisadvantagedOtherGender:this.calculateTotal(item, 'disadvantagedOtherGender'),
					totaldisabledMale:this.calculateTotal(item, 'disabledMale'),
					totaldisabledFemale:this.calculateTotal(item, 'disabledFemale'),
					totaldisabledOtherGender:this.calculateTotal(item, 'disabledOtherGender'),
					
				}
				this.cd.detectChanges();
			})
		
			
	}

	totalCases(item) {
		let totalCases = 0;
		item.items.forEach(x => {
			totalCases = totalCases + x.totalMale + x.totalFemale + x.totalOtherGender;
		});
		return totalCases;
	}
	calculateTotal(item, propertyName) {
		let totalCases = 0;
		item.items.forEach(x => {
			totalCases = totalCases + x[propertyName];
		});
		return totalCases;
	}

	setLanguage(lang) {
		this.languages.forEach((language: LanguageFlag) => {
			if (language.lang === lang) {
				language.active = true;
				this.language = language;
			} else {
				language.active = false;
			}
		});
		this.transalationService.setLanguage(lang);
		this.loadReportSummery();
		// if(this.currentLanguage == 'en')
		// {
		// 	this.serviceEn = this.serviceEn;
		// }
		// else
		// {
		// 	this.serviceBn = this.serviceBn;
		// }
	}

	setSelectedLanguage(): any {
		this.setLanguage(this.transalationService.getSelectedLanguage());
	}

	loadReportSummery() {
		
		 this.welcomeService.getSupport().subscribe(data => {
			this.apiResponseModel = data;

			this.totalMale= this.calculateTotal(data, 'totalMale'),
			this.totalFemale= this.calculateTotal(data, 'totalFemale'),
			this.totalOtherGender= this.calculateTotal(data, 'totalOtherGender'),

			
			// this.apiResponseModel.items.forEach(item => {
			// 	item['serviceEn'] = this.referalServiceNameEn(item);
			// 	item["serviceBn"] = this.referalServiceNameBn(item);
			// });
			this.cd.detectChanges();
			
		})
	}

// 	referalServiceNameEn = (referalServiceNameEn: any) => {
		
// 		 return referalServiceNameEn
// 	}

// 	referalServiceNameBn = (referalServiceNameBn: any) => {
		
// 		return referalServiceNameBn
//    }


}
