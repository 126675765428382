export enum AccessEnum{
        None = 'None',
        Add = 'Add',
        Edit = 'Edit',
        Delete = 'Delete',
        View = 'View',
        Assign = 'Assign',
        Approve = 'Approve',
        Reject = 'Reject',
        Transfer = 'Transfer',
        Cancel = 'Cancel',
        GetNotification="GetNotification",
        DataExport='DataExport'
}