export class CaseDropoutEditModel{
    
    caseId:number;
    officeId: number; 
    dropoutDate:Date;
    reason:string;  
    reasonOthers:string;     
    initiatorComments:string;
    hasAttachment:number;
   
}
export class CaseDropoutApprovalModel{
    id: number;
    caseId:number;
    officeId: number; 
    supervisorComment: string;
    approvalStatus: number;
    childNameBn:string;
    childNameEn:string;
    registrationNo:string;
    officeNameEn:string;
    supervisor:string;
}
