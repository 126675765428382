import { NumberSymbol } from '@angular/common';

export class RiskAssesmentEditModel {
    caseId: number;
    officeId: number;
    formName: string;
    verifiedDate: string;
    totalScore: number;
    results: RiskAssessmentAnswerModel[];
    resultIndicators:RiskAssessmentIndicatorModel[];
}
export class RiskAssessmentAnswerModel {
    questionId: number;
    answerId: number
    score: number;
    comments: string;
}
export class RiskAssessmentIndicatorModel {
    questionId: number;
    indicatorId: number;
}