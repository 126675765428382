import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { OfficeService, OfficeTypeService } from '../../../services';
import { Subscription } from 'rxjs';
import { UserSearchModel, OfficeTypeModel, OfficeModel } from '../../../model';
import { TranslationService } from '../../../../core/_base/layout';


@Component({
	selector: 'users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, OnDestroy {
	currentPage = 1;
	currentLanguage = 'en';
	defaultSearchId=0;
	userSearchModel:UserSearchModel;
	officeSubscription: Subscription;
	officeTypeSubscription: Subscription;
	languageChangeSubscription:Subscription;
	officeModels: OfficeModel[];
	officeTypes: OfficeTypeModel[];
	constructor(private officeService: OfficeService,
		private officeTypeService: OfficeTypeService,
		private transalationService: TranslationService,
		private cd: ChangeDetectorRef) {
		this.currentLanguage = this.transalationService.getSelectedLanguage();
	}
	ngOnInit() {
		this.userSearchModel=this.officeService.userSearchModel;
		//this.transalationService.setOfficeChangEvent(this.userSearchModel);
		//console.log(this.userSearchModel);
		this.loadOffice();
		this.loadOfficeTypes();
		this.languageChangeSubscription = this.transalationService.getLangChangeEvent().subscribe(lang => {
			this.currentLanguage = lang.lang;
		})
		
	}
	loadOffice() {
		this.officeSubscription=this.officeService.getAll(1, 5000,this.userSearchModel.officeTypeId).subscribe(data => {
			this.officeModels = data.items;
			this.cd.detectChanges();
		})
	}
	onOfficeTypeChange(){
		this.officeModels=[];
		this.loadOffice();
		this.transalationService.setOfficeChangEvent(this.userSearchModel);
	}
	loadOfficeTypes() {
		this.officeTypeSubscription = this.officeTypeService.getAll().subscribe(data => {
			this.officeTypes = data.items;
			this.cd.detectChanges();
		})
	}
	ngOnDestroy() {
	   this.officeSubscription.unsubscribe();
	   this.languageChangeSubscription.unsubscribe();
	   this.officeSubscription.unsubscribe();
	}
	onOfficeChange()
	{
		this.transalationService.setOfficeChangEvent(this.userSearchModel);
	}

}
