import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth';

@Injectable({
    providedIn: 'root',
})
export class GeoLocationService {
    apiName = 'GeoLocations';
    constructor(private httpClient: HttpClient,private authService:AuthService) {

    }
    getAll(locationLevel) {
        return this.httpClient.get<any>(environment.apiUrl + this.apiName+"?PageSize=10&LocationLevel="+locationLevel,this.authService.getRequestHeader());
    }
}