import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  pageModel = {
		currentPage: 1,
		pageSize: 20
	}
  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  	getAll<ApiResponseModel>(pageNumber: number, pageSize: number) {
		return this.httpClient.get<any>(environment.apiUrl + "Forms" + '?Page=' + pageNumber + '&PageSize=' + pageSize, this.authService.getRequestHeader());
	}
	getAllFormsByType<ApiResponseModel>(pageNumber: number, pageSize: number,formType:string) {
		return this.httpClient.get<any>(environment.apiUrl + "Forms" + '?formType='+ formType +'&Page=' + pageNumber + '&PageSize=' + pageSize, this.authService.getRequestHeader());
	}

	getDataViewList<ApiResponseModel>(pageNumber: number, pageSize: number,dataViewModel:any) {
		var query='Page=' + pageNumber + '&PageSize=' + pageSize;
		if(dataViewModel.formGroup) {
			query += '&formGroup='+ dataViewModel.formGroup;
		}
		if(dataViewModel.submittedBy) {
			query += '&submittedBy='+ dataViewModel.submittedBy;
		}
		if(dataViewModel.dateFrom) {
			query +='&dateFrom='+ dataViewModel.dateFrom;
		}
		if(dataViewModel.dateTo) {
			query +='&dateTo='+ dataViewModel.dateTo;
		}
		if(dataViewModel.officeTypeId) {
			query += '&officeTypeId='+ dataViewModel.officeTypeId;
		}
		if(dataViewModel.officeId) {
			query += '&officeId='+ dataViewModel.officeId;
		}
		return this.httpClient.get<any>(environment.apiUrl + "DataExports/Views" + '?'+ query, this.authService.getRequestHeader());

	}

	getAllExportList<ApiResponseModel>(dataViewModel:any,pageNumber: number, pageSize: number) {
		var query='Page=' + pageNumber + '&PageSize=' + pageSize;
		if(dataViewModel.formGroup) {
			query += '&formGroup='+ dataViewModel.formGroup;
		}
		if(dataViewModel.submittedBy) {
			query += '&submittedBy='+ dataViewModel.submittedBy;
		}
		if(dataViewModel.dateFrom) {
			query +='&dateFrom='+ dataViewModel.dateFrom;
		}
		if(dataViewModel.dateTo) {
			query +='&dateTo='+ dataViewModel.dateTo;
		}
		return this.httpClient.get<any>(environment.apiUrl + "DataExports/Histories" + '?' + query, this.authService.getRequestHeader());
	}
	
	postExportData=(dataViewModel:any)=>{
		var query= "";
		if(dataViewModel.formGroup) {
			query += 'formGroup='+ dataViewModel.formGroup;
		}
		if(dataViewModel.submittedBy) {
			query += '&submittedBy='+ dataViewModel.submittedBy;
		}
		if(dataViewModel.dateFrom) {
			query +='&dateFrom='+ dataViewModel.dateFrom;
		}
		if(dataViewModel.dateTo) {
			query +='&dateTo='+ dataViewModel.dateTo;
		}
		if(dataViewModel.officeTypeId) {
			query += '&officeTypeId='+ dataViewModel.officeTypeId;
		}
		if(dataViewModel.officeId) {
			query += '&officeId='+ dataViewModel.officeId;
		}
		return this.httpClient.post<any>(environment.apiUrl + "DataExports/Histories" + '?' + query,{},this.authService.getRequestHeader());
	}

	deleteExportedFile=(id:number)=> {
		return this.httpClient.delete(environment.apiUrl + "DataExports/Histories/"+id,this.authService.getRequestHeader());
	}

	getDownloadFileLink=(id:number)=> {
		return this.httpClient.get(environment.apiUrl + "DataExports/Histories/"+id+"/Download",this.authService.getBlobRequestHeader());
	}

	getDataViewOfForms=(id:number,formTypes:string)=>{
		return this.httpClient.get<any>(environment.apiUrl + "DataExports/" + formTypes + "/" + id, this.authService.getRequestHeader());
	}



}
