export class AddDraftDetailAssessmentFormPartCRequest {
   detailAssessmentFormPartCData: string;
}


export class DetailAssessmentPartCEditModel {
   draftId:number;
   draftName: string;
   caseId:number;
   officeId:number;
   verifiedDate:string;
   isReferredOffice:boolean;
   conclution:string; 
   //instruction:string;  
   neededChanges:string;
   observation:string;
   childViews:string;
   parentOrCaregiverViews:string;
}



