import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth';
import { Observable,BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class NotificationService {
    apiName = 'Notifications';
    currentNotification = new BehaviorSubject(null);
    private data = new BehaviorSubject(false);
    showLoader = this.data.asObservable();


    constructor(private httpClient: HttpClient, private authService: AuthService) {

    }
    getAll() { 
        let notificationList = this.httpClient.get<any>(environment.apiUrl + this.apiName, this.authService.getRequestHeader()).pipe(shareReplay());  
        this.currentNotification.next(notificationList);
        return notificationList ;
    }
    getSupport() {
        return this.httpClient.get<any>(environment.apiUrl + this.apiName + '/Suports', this.authService.getRequestHeader())
    }
    markAllNotificationAsRead() {

       return this.httpClient.patch<any>(environment.apiUrl + this.apiName + '/' +  'MarkAllAsRead',{}, this.authService.getRequestHeader());
    }

    setLoaderVisibleStatus(item: any) {
        this.data.next(item);
    }

}