import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth';
import { CaseReopenApprovalModel, CaseReopenEditModel } from '../model';

@Injectable({providedIn:'root'})
export class CaseReOpenFormsService{
    apiName = 'CaseReOpenForms';
    constructor(private httpClient: HttpClient,private authService:AuthService) {

    }
     
    //case Reopen

    caseReopenSave(model:CaseReopenEditModel){
        return this.httpClient.post(`${environment.apiUrl}${'CaseReOpenForms'}`,model,this.authService.getRequestHeader());
    }

    getCaseReopenById(caseReopenId) {
        return this.httpClient.get<any>(environment.apiUrl + 'CaseReOpenForms' +'/'+caseReopenId,this.authService.getRequestHeader());
    }

    caseReopenApproval(caseReopenId,model:CaseReopenApprovalModel){
        return this.httpClient.patch(`${environment.apiUrl}${'CaseReOpenForms'}/${caseReopenId}/Approvals`,model,this.authService.getRequestHeader());
    }
}