export class AddDraftDetailAssessmentFormPartB3DRequest {
   detailAssessmentFormPartB3DData: string;
}


export class DetailAssessmentPartB34EditModel {
   draftId:number;
   draftName: string;
   caseId:number;
   officeId:number;
   verifiedDate:string;
   isReferredOffice:boolean;
   communityFacilities:number[];
   hasAntiSocialActivity:string;
   //antiSocialActivities:number[];
   //antiSocialActivityOthers:string;
   observation:string;
}



