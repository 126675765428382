import { Component, OnInit, OnDestroy } from "@angular/core";

@Component({
    selector: 'office-search',
    templateUrl: './office-search.component.html',
    styleUrls: ['./office-search.component.css']
})
export class OfficeSearchComponent implements OnInit, OnDestroy {
    ngOnInit() {

    }
    ngOnDestroy() {

    }
}