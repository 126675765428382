export class InterventionActionEditModel{
    
    caseId:number;
    officeId: number; 
   // interventionAction:number;  
    //interventionActionOthers:string 
    //implementer:string;
    //implementationDate:Date;
    implementationActions : InterventionActionPlanDetailsModel[];
    date:String;
    comments:string;
}

export class InterventionActionPlanDetailsModel {
    interventionAction : number;
    implementationProcess: string;
    implementationDate:string;
    implementer:string;
    interventionActionOthers:string ;
}

