import { Component, Input, ChangeDetectorRef } from "@angular/core";
import { Subscription } from 'rxjs';
import { TranslationService } from '../../../../../core/_base/layout'
// import { TranslationService } from '';

@Component({
	selector: 'translate-name',
	templateUrl: './translate-name.component.html'
})
export class TranslateNameComponent{
    @Input() englishName:string;
    @Input() banglaName:string;
    lang = 'bn';
    languageChangeSubscription: Subscription;
    constructor(private translationService: TranslationService,private cd:ChangeDetectorRef){
        
    }
    ngOnInit(){
        this.lang = this.translationService.getSelectedLanguage();
        this.languageChangeSubscription = this.translationService.getLangChangeEvent().subscribe(lang => {
            this.lang = lang.lang;
            this.cd.detectChanges();
		})
    }
    get name(){
        return this.lang==='en'?this.englishName:this.banglaName;
    }
}