// Angular
import { EventEmitter, Injectable } from "@angular/core";
// Tranlsation
import { TranslateService } from "@ngx-translate/core";

export interface Locale {
	lang: string;
	// tslint:disable-next-line:ban-types
	data: Object;
}

@Injectable({
	providedIn: "root",
})
export class TranslationService {
	officeChangeEvent: EventEmitter<any> = new EventEmitter<any>();
	// Private properties
	private langIds: any = [];

	/**
	 * Service Constructor
	 *
	 * @param translate: TranslateService
	 */
	constructor(private translate: TranslateService) {
		// add new langIds to the list
		this.translate.addLangs(["en", "bn"]);

		// this language will be used as a fallback when a translation isn't found in the current language
		this.translate.setDefaultLang("bn");
	}

	/**
	 * Load Translation
	 *
	 * @param args: Locale[]
	 */
	loadTranslations(...args: Locale[]): void {
		const locales = [...args];

		locales.forEach((locale) => {
			// use setTranslation() with the third argument set to true
			// to append translations instead of replacing them
			this.translate.setTranslation(locale.lang, locale.data, true);

			this.langIds.push(locale.lang);
		});

		// add new languages to the list
		this.translate.addLangs(this.langIds);
	}

	/**
	 * Setup language
	 *
	 * @param lang: any
	 */
	setLanguage(lang) {
		if (lang) {
			this.translate.use(this.translate.getDefaultLang());
			this.translate.use(lang);
			localStorage.setItem("language", lang);
		}
	}

	getLangChangeEvent() {
		return this.translate.onLangChange;
	}
	/**
	 * Returns selected language
	 */
	getSelectedLanguage(): any {
		return localStorage.getItem("language") || this.translate.getDefaultLang();
	}

	setOfficeChangEvent(userSearchModel: any) {
		this.officeChangeEvent.emit(userSearchModel);
	}

	getOfficeChangEvent() {
		return this.officeChangeEvent;
	}
	getTanslationInComponent(key: any) {
		let translateValue = "";
		this.translate.get(key).subscribe((data: any) => {
			translateValue = data;
		});
		return translateValue;
	}
}
