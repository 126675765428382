import { Component } from "@angular/core";
import { AuthService } from "../../../../core/auth";

@Component({
	selector: "update-profile",
	templateUrl: "./update-profile.component.html",
	styleUrls: ["./update-profile.component.css"],
})
export class UpdateProfileComponent {
	_user: any;
	constructor(private authService: AuthService) {}

	ngOnInit(): void {
		//TODO://commented ehsan
		//this.user$ = this.store.pipe(select(currentUser));
		var user = this.authService.getTokenData();
		this._user = {
			user_id: user.user_id,
			fullname: user.sub,
			pic: "assets/media/users/no-image.jpg",
			designation: user.designation,
		};
	}
}
