import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth';
import { CaseDropoutApprovalModel, CaseDropoutEditModel } from '../model';

@Injectable({providedIn:'root'})
export class CaseDropoutService{
    apiName = 'CaseDropoutForms';
    constructor(private httpClient: HttpClient,private authService:AuthService) {

    }
     //case Dropout

     caseDropoutSave(model:CaseDropoutEditModel){
        return this.httpClient.post(`${environment.apiUrl}${'CaseDropoutForms'}`,model,this.authService.getRequestHeader());
    }

    getCaseDropoutById(caseDropoutId) {
        return this.httpClient.get<any>(environment.apiUrl + 'CaseDropoutForms' +'/'+caseDropoutId,this.authService.getRequestHeader());
    }

    caseDropoutApproval(caseDropoutId,model:CaseDropoutApprovalModel){
        return this.httpClient.patch(`${environment.apiUrl}${'CaseDropoutForms'}/${caseDropoutId}/Approvals`,model,this.authService.getRequestHeader());
    }
    
}