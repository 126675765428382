export class FormReferralEditModel{
    
    caseId:number;
    fromOfficeId: number; 
    toOfficeId: number;
    referralDate:Date;
    referralReason:string;  
    initiatedBy:string;
    intiatorComments:string;
    referredForms: number[]; 
    
}

export class FormReferralApprovalModel{
    id:number;
    approverType: string; 
    caseId: number;
    approverComment:string;  
    referredTo:string 
    referralStatus:string;
    childNameBn:string;
    childNameEn:string;
    registrationNo:string;
    officeNameEn:string;
    fromOfficeNameEn:string;
    fromOfficeNameBn:string;
    toOfficeNameEn:string;
    toOfficeNameBn:string;
    officeId: number; 
    approvalStatus: number;
    supervisorComment:string;
    

}