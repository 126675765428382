import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../core/auth";
import { OfficeCatchmentEditModel, OfficeSearch, UserSearchModel } from "../model";
import { OfficeEditModel } from "../model/office/office-edit.model";
import { ApiResponseModel } from "../model/shared/api-response.model";

@Injectable({
	providedIn: "root",
})
export class OfficeService {
	apiName = "Offices";
	searchModel: OfficeSearch = {
		officeTypeId: 0,
		divisionId: 0,
		districtId: 0,
		upazillaId: 0,
	};
	userSearchModel: UserSearchModel = {
		officeTypeId: 0,
		officeId: 0,
	};
	constructor(private httpClient: HttpClient, private authService: AuthService) {}
	getAll(pageNumber: number = 1, pageSize: number = 10000, officeTypeId: number = 0, assignedOfficesOnly = false) {
		var search = "?Page=" + pageNumber + "&PageSize=" + pageSize;
		if (officeTypeId && officeTypeId > 0) {
			search = "?Page=" + pageNumber + "&PageSize=" + pageSize + "&officeTypeId=" + officeTypeId + "&assignedOfficesOnly=" + assignedOfficesOnly;
		}
		return this.httpClient.get<any>(environment.apiUrl + this.apiName + search, this.authService.getRequestHeader());
	}
	getAllBySearch(pageNumber: number = 1, pageSize: number = 10000, officeSearch: OfficeSearch = null) {
		var search = "?Page=" + pageNumber + "&PageSize=" + pageSize;
		if (officeSearch) {
			if (officeSearch.officeTypeId > 0) {
				search = search + "&officeTypeId=" + officeSearch.officeTypeId;
			}
			if (officeSearch.divisionId > 0) {
				search = search + "&divisionId=" + officeSearch.divisionId;
				if (officeSearch.districtId > 0) {
					search = search + "&districtId=" + officeSearch.districtId;
					if (officeSearch.upazillaId > 0) {
						search = search + "&upazillaId=" + officeSearch.upazillaId;
					}
				}
			}
		}
		return this.httpClient.get<any>(environment.apiUrl + this.apiName + search, this.authService.getRequestHeader());
	}

	getById(id) {
		return this.httpClient.get<any>(environment.apiUrl + this.apiName + "/" + id, this.authService.getRequestHeader());
	}
	getByOfficeTypeId(officeTypeId, authorizedOfficeTypesOnly: boolean = false) {
		if (officeTypeId && officeTypeId > 0) return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + "?officeTypeId=" + officeTypeId + "&authorizedOfficesOnly=" + authorizedOfficeTypesOnly, this.authService.getRequestHeader());
	}
	getByOfficeTypeIdCaseList(pageNumber: number = 1, pageSize: number = 10000, officeTypeId, authorizedOfficeTypesOnly: boolean = false) {
		var search = "?Page=" + pageNumber + "&PageSize=" + pageSize;

		if (officeTypeId && officeTypeId > 0) return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + "?officeTypeId=" + officeTypeId + "&authorizedOfficesOnly=" + authorizedOfficeTypesOnly + search, this.authService.getRequestHeader());
	}
	save(model: OfficeEditModel) {
		return this.httpClient.post(environment.apiUrl + this.apiName, model, this.authService.getRequestHeader());
	}
	update(id: number, model: OfficeEditModel) {
		return this.httpClient.put(environment.apiUrl + this.apiName + "/" + id, model, this.authService.getRequestHeader());
	}
	delete(id) {
		return this.httpClient.delete(environment.apiUrl + this.apiName + "/" + id, this.authService.getRequestHeader());
	}
	addCatchments(officeId: number, models: OfficeCatchmentEditModel[]) {
		return this.httpClient.post(`${environment.apiUrl}${this.apiName}/${officeId}/Catchments`, { Catchments: models }, this.authService.getRequestHeader());
	}
	updateCatchments(officeId: number, models: OfficeCatchmentEditModel[]) {
		return this.httpClient.put(`${environment.apiUrl}${this.apiName}/${officeId}/Catchments`, { Catchments: models }, this.authService.getRequestHeader());
	}
	getOfficeUsers(officeId: number, status: string, checkAuthorization = true) {
		if (officeId && officeId > 0) return this.httpClient.get<ApiResponseModel>(environment.apiUrl + this.apiName + "/" + officeId + "/Users" + "?status=" + status, this.authService.getRequestHeader());
	}
}
