export class CaseTransferEditModel{
    
    caseId:number;
    caseType: number;
    fromOfficeId: number; 
    transferLocation: string;
    toOfficeId: number;
    transferDate:Date;
    transferReason:string;  
    initiatorComments:string;
   
}

export class CaseTransferApprovalModel{
    id:number;
    caseId:number;
    officeId: number; 
    reason:string;  
    isAssessmentNeeded:string 
    closureDate:Date;
    supervisor:string;
    supervisorComment: string;
    approvalStatus: number;
    transferStatus: string;
    childNameBn:string;
    childNameEn:string;
    registrationNo:string;
    officeNameEn:string;
    fromOfficeNameEn:string;
    fromOfficeNameBn:string;
    toOfficeNameEn:string;
    toOfficeNameBn:string;

}