// Angular
import { Injectable } from '@angular/core';
// RxJS
import { Subject } from 'rxjs';
import { AuthService } from '../../../auth';

@Injectable()
export class MenuConfigService {
	// Public properties
	onConfigUpdated$: Subject<any>;
	// Private properties
	private menuConfig: any;

	/**
	 * Service Constructor
	 */
	constructor(private authService:AuthService) {
		// register on config changed event and set default config
		this.onConfigUpdated$ = new Subject();
	}

	/**
	 * Returns the menuConfig
	 */
	getMenus() {
		return this.menuConfig;
	}

	/**
	 * Load config
	 *
	 * @param config: any
	 */
	loadConfigs(config: any) {
		config.aside.items = this.getPermittedMenu(config.aside.items);
		this.menuConfig=config;
		this.onConfigUpdated$.next(this.menuConfig);
	}
	getPermittedMenu(menuItems:any){
		var menuPermission = this.authService.getTokenData();
		// menuPermission.menuPermissions.push("MyInitiatedCaseList");

		var permittedMenu = [];
		if (menuPermission.menuPermissions && menuPermission.menuPermissions.length > 0) {
			menuPermission.menuPermissions.forEach(item => {
				var menu = menuItems.find(x => x.key === item);
				if (menu) {
					if(menu.submenu && menu.submenu.length>0)
					{
						var subMenu=menu.submenu;
						menu.submenu=[];
						subMenu.forEach(s => {
							if(menuPermission.menuPermissions.find(item=>item===s.key))
							{
								menu.submenu.push(s);
							}
						});
					}
					permittedMenu.push(menu);
				}
			});
		}
		return permittedMenu;
		
	}
}
