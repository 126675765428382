export class AddDraftDetailAssessmentFormPartB2BRequest {
   detailAssessmentFormPartB2BData: string;
}


export class DetailAssessmentPartB22EditModel {
   draftId:number;
   draftName: string;
   caseId:number;
   officeId:number;
   verifiedDate:string;
   isReferredOffice:boolean;
   isAdored:string;
   //notAdoredReasons:number[];
   //notAdoredReasonOthers:string;
   parentsLookFor:number;  
   observation:string;
   


}



