
export class DetailAssessmentPartB11EditModel {
   draftName: string;
   draftId:number;
   caseId:number;
   officeId:number;
   verifiedDate:string;
   isReferredOffice:boolean;
   hasVisitedDoctor:string;
   isAvgHeightWeihgtOk:string;
   hasChronicDisease:string;
   //chronicDiseases:number[];
   hasReceivedTreatment:string;
   //reasonsOfNoTreatment:number[];
   //reasonOfNoTreatmentOthers:string;
   //chronicDiseasesOthers:string;
   hasVaccinationProvided:boolean;
   //vaccinationNames:number[];
   //vaccinationOthers:string;
   observation:string; 
}



