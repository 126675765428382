import { Subscription } from "rxjs";
// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from "./core/_base/layout";
// language list
import { locale as bnLang } from "./core/_config/i18n/bn";
import { locale as enLang } from "./core/_config/i18n/en";

@Component({
	// tslint:disable-next-line:component-selector
	selector: "body[kt-root]",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
	// Public properties
	title = "Case Management";
	loader: boolean;

	lang: string = "bn";
	languageChangeSubscription: Subscription;

	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param translationService: TranslationService
	 * @param router: Router
	 * @param layoutConfigService: LayoutCongifService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(private translationService: TranslationService, private router: Router, private layoutConfigService: LayoutConfigService, private splashScreenService: SplashScreenService, private renderer: Renderer2) {
		// register translations
		this.translationService.loadTranslations(enLang, bnLang);
		this.lang = translationService.getSelectedLanguage();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */

	ngOnInit(): void {
		// enable/disable loader
		this.loader = this.layoutConfigService.getConfig("loader.enabled");

		this.languageChangeSubscription = this.translationService.getLangChangeEvent().subscribe((lang) => {
			this.lang = lang.lang;
			this.setFont(this.lang);
		});
		const routerSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				// hide splash screen
				//this.splashScreenService.hide();

				// scroll to top on every route change
				window.scrollTo(0, 0);

				// to display back the body content
				setTimeout(() => {
					document.body.classList.add("kt-page--loaded");
				}, 500);
			}
		});
		this.unsubscribe.push(routerSubscription);
	}

	setFont(lang: string) {
		const fontFamily = lang === "bn" ? "NikoshBAN" : "Poppins, Roboto, sans-serif";
		this.renderer.setStyle(document.body, "font-family", fontFamily);
		// Optionally, set a CSS custom property (variable) for the root element
		this.renderer.setStyle(document.documentElement, "--font-family", fontFamily);
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.unsubscribe.forEach((sb) => sb.unsubscribe());
	}
}
