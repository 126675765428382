// USA
export const locale = {
	lang: 'en',
	data: {
		USER: {
			TITLE:'User Mangement',
			SEARCH:{
				OFFICETYPE:'Office Type',
				OFFICENAME:'Office Name',
				CHOOSEOFFICETYPE:'Choose Office Type',
				CHOOSEOFFICENAME:'Choose Office Name'
			}
		}
	}
};