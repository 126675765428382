// USA
export const locale = {
	lang: 'bn',
	data: {
		USER: {
			TITLE:'ব্যবহারকারী ম্যানেজমেন্ট',
			SEARCH:{
				OFFICETYPE:'অফিসের টাইপ',
				OFFICENAME:'অফিসের নাম',
				CHOOSEOFFICETYPE:'অফিস এর ধরণ নির্বাচন নিন',
				CHOOSEOFFICENAME:'অফিস নির্বাচন করুন'
			}
		}
	}
};