import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../core/auth";

@Injectable({ providedIn: "root" })
export class DivisionService {
	apiName = "Divisions";
	constructor(private httpClient: HttpClient, private authService: AuthService) {}

	getAll(locationType = 0) {
		return this.httpClient.get<any>(`${environment.apiUrl}${this.apiName}?PageSize=200&locationType=${locationType}&pageSize=${300}`, this.authService.getRequestHeader());
	}

	getByDivisionId(divisionId: number) {
		return this.httpClient.get<any>(`${environment.apiUrl}${this.apiName}/${divisionId}`, this.authService.getRequestHeader());
	}
}
