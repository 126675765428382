import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog-approval',
  templateUrl: './confirm-dialog-approval.component.html',
  styleUrls: ['./confirm-dialog-approval.component.css']
})
export class ConfirmDialogApprovalComponent implements OnInit {
  title: string;
  message: string;
  isConfirmation:boolean
  subsription: any;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogApprovalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.isConfirmation=data.isConfirmation
  }

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
  ngOnDestroy() {
   
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {

  constructor(public title: string, public message: string,public isConfirmation:boolean) {
  }
}

