import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateNameComponent } from './office-name/transalate-name.component';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
    ],
    exports: [
        TranslateNameComponent
    ],
    declarations: [
        TranslateNameComponent
    ],
})
export class TranslateSharedModule {

}