import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth';
import { CaseTransferApprovalModel, CaseTransferEditModel } from '../model';

@Injectable({providedIn:'root'})
export class CaseTransferService{
    apiName = 'CaseTransferForms';
    constructor(private httpClient: HttpClient,private authService:AuthService) {

    }
     // Case Transfer
     caseTransferSave(model:CaseTransferEditModel){
        return this.httpClient.post(`${environment.apiUrl}${'CaseTransferForms'}`,model,this.authService.getRequestHeader());
    }

    getCaseTransferById(caseTransferId) {
        return this.httpClient.get<any>(environment.apiUrl + 'CaseTransferForms' +'/'+caseTransferId,this.authService.getRequestHeader());
    }

    caseTransferApproval(caseTransferId,model:CaseTransferApprovalModel){
        return this.httpClient.patch(`${environment.apiUrl}${'CaseTransferForms'}/${caseTransferId}/Approvals`,model,this.authService.getRequestHeader());
    }
}