import { NgModule } from "@angular/core";
import { ConfirmDialogComponent } from "./confirm-dialog.component";
import { CommonModule } from "@angular/common";
import { MatButtonModule, MatDialogModule } from "@angular/material";
import { DialogService } from '../../services';
import { TranslateSharedModule } from "../../pages/shared/translate/translate.shared.module";


@NgModule({
  declarations: [
    ConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    TranslateSharedModule
  ],
  exports: [
    ConfirmDialogComponent
  ],
  entryComponents: [ConfirmDialogComponent],
  providers: [
    DialogService
  ]
}
)
export class ConfirmDialogModule {

}
