export enum FormGroupEnum {
    IntakeForm = 1,
    RiskAssesmentForm = 2,
    DetailAssessmentFormPartA = 3,
    DetailAssessmentFormPartB1A = 4,
    DetailAssessmentFormPartB1B = 5,
    DetailAssessmentFormPartB1C = 6,
    DetailAssessmentFormPartB1D = 7,
    DetailAssessmentFormPartB1E = 8,
    DetailAssessmentFormPartB1F = 9,
    DetailAssessmentFormPartB1G = 10,
    DetailAssessmentFormPartB1H = 11,
    DetailAssessmentFormPartB1I = 12,
    DetailAssessmentFormPartB2A = 13,
    DetailAssessmentFormPartB2B = 14,
    DetailAssessmentFormPartB2C = 15,
    DetailAssessmentFormPartB3A = 16,
    DetailAssessmentFormPartB3B = 17,
    DetailAssessmentFormPartB3C = 18,
    DetailAssessmentFormPartB3D = 19,
    DetailAssessmentFormPartC = 20,
    InterventionPlanForm = 21,
    InterventionActionForm = 22,
    FollowupForm = 23,
    CaseTransferForm = 24,
    CaseRefferalForm = 25,
    CaseReintegrationform = 26,
    CaseClosureForm = 27,
    CaseDropoutForm = 28,
    CaseReopen = 29,
    CaseNotes = 30,
    ComplainIssue = 31
}