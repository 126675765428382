import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiResponseModel } from '../model/shared/api-response.model';
import { AuthService } from '../../core/auth';
import { AccessEnum, FormPermissionEditModel, FormPermissionEditModels, FormReferralApprovalModel, FormReferralEditModel, } from '../model';


@Injectable({
	providedIn: 'root',
})
export class FormsRefferalService {
	//apiName = 'Forms';
	constructor(private httpClient: HttpClient, private authService: AuthService) {

	}
	getAll<ApiResponseModel>(pageNumber: number, pageSize: number) {
		return this.httpClient.get<any>(environment.apiUrl +  '?Page=' + pageNumber + '&PageSize=' + pageSize, this.authService.getRequestHeader());
	}
	
	save(model:FormReferralEditModel) {
		return this.httpClient.post<any>(environment.apiUrl +'FormReferralForms',model, this.authService.getRequestHeader());
	}
	update(model:FormReferralEditModel) {
		return this.httpClient.put<any>(environment.apiUrl  +'FormReferralForms',model, this.authService.getRequestHeader());
	}


	getformRefferalById(fromRefferalId) {
        return this.httpClient.get<any>(environment.apiUrl + 'FormReferralForms' +'/'+fromRefferalId,this.authService.getRequestHeader());
    }

    formRefferalApproval(fromRefferalId,model:FormReferralApprovalModel, approverType){
        return this.httpClient.patch(`${environment.apiUrl}${'FormReferralForms'}/${fromRefferalId}/Approvals/${approverType}`,model,this.authService.getRequestHeader());
    }

	

}