
export class DetailAssessmentPartB19EditModel {
   draftName: string;
   draftId:number;
   caseId:number;
   officeId:number;
   verifiedDate:string;
   hasArtisticAbility:boolean;
   isRegularInSchool:boolean;
   //schoolIrregularityReasons:number[];
   //schoolIrregularityReasonOthers:string;
   doesFeelEncourageToStudy:string;
   doesNotFeelEncourageReasons:number[];
   doesNotFeelEncourageReasonOthers:string;
   aimInLife:number[];
   aimInLifeOthers:boolean;
   observation:string; 
}



