export class CaseClosureEditModel{
    
    caseId:number;
    officeId: number; 
    reason:string;  
    isAssessmentNeeded:string 
    closureDate:Date;
}

export class CaseClosureApprovalModel{
    caseId:number;
    officeId: number; 
    reason:string;  
    isAssessmentNeeded:string 
    closureDate:Date;
    supervisor:string;
    supervisorComment: string;
    approvalStatus: number;
    childNameBn:string;
    childNameEn:string;
    registrationNo:string;
    officeNameEn:string;

}