export class UserEditModel{
    userName:string;
    firstName: string;
    lastName: string;
    password:string;
    confirmPassword:string;
    email:string;
    phoneNumber:string;
    employeeId:string;
    designation:string;
    status:number;
    userOffices:UserOffice[];
    userRoles:UserRole[];
}
export class UserOffice{
    userId:number;
    officeId:number;
    isMainOffice:boolean;
}
export class UserRole
{
   userId:number;
   roleId:number;
}
