export class AddDraftDetailAssessmentFormPartB3BRequest {
   detailAssessmentFormPartB3BData: string;
}


export class DetailAssessmentPartB32EditModel {
   draftId:number;
   draftName: string;
   caseId:number;
   officeId:number;
   verifiedDate:string;
   isReferredOffice:boolean;
   extFamilyAttendFamilyProgram:number;
   //extFamilyAssistFamilyProgram:number;
   extFamilyAssistForCare:number;
   //extFamilyInAttached:string;
   //isSecureAttachExtFamily:number; 
   observation:string;
   


}



