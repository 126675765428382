import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserOfficeModel } from '../model';
import { AuthService } from '../../core/auth';


@Injectable({
    providedIn: 'root',
})
export class UserOfficeService {
    apiName = 'UserOffices';
    constructor(private httpClient: HttpClient,private authService:AuthService) {

    }
    save(userOffice) {
        return this.httpClient.post(environment.apiUrl + this.apiName,userOffice,this.authService.getRequestHeader());
    }
}