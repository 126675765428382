// USA
export const locale = {
	lang: 'bn',
	data: {
		USER: {
			CHANGEPASSWORD: {
				TITLE: 'পাসওয়ার্ড পরিবর্তন',
				BUTTONCAPTION: {
					BACK: 'পিছনে যান',
					SUBMIT: 'সাবমিট'
				},
				NOTIFICATION: {
					UPDATE: "পাসওয়ার্ড সফলভাবে পরিবর্তন হয়েছে!!",
					ERROR: 'ERROR: No data updated.'
				},
				CONTROLCAPTION: {
					
					USERNAME: {
						NAME: 'User Name',
						PLACEHOLDER: 'Enter User Name'
					},
					FULLNAME: {
						NAME: 'নাম',
					},
					LASTNAME: {
						NAME: 'Last Name',
						PLACEHOLDER: 'Enter Last Name'
					},
					
					OLDPASSWORD: {
						NAME: 'পুরাতন পাসওয়ার্ড',
						PLACEHOLDER: 'Enter Old Password'
					},
					NEWPASSWORD: {
						NAME: 'নতুন পাসওয়ার্ড',
						PLACEHOLDER: 'Enter New Password'
					},
					CONFIRMPASSWORD: {
						NAME: 'Confirm Password',
						PLACEHOLDER: 'Enter Password'
					}
					
				}
			}
		}
	}
};