export class CaseRefferalEditModel{
    
    caseId:number;
    fromOfficeId: number; 
    referralPlace: string;
    toOfficeId: number;
    toOfficeName: string;
    toOfficeAddress: string;
    referralDate:Date;
    referralReason:string; 
    referralService: string; 
    referralServiceOthers: string;
    gdNumber:string ;
    initiatorComments:string;
    responsibleSocialWorker: string;
   
}

export class CaseRefferalApprovalModel{
    id: number;
    caseId:number;
    officeId: number; 
    reason:string; 
    approverComments: string;
    approvalStatus: number;
    childNameBn:string;
    childNameEn:string;
    registrationNo:string;
    officeNameEn:string;

}