import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth';
import { CaseReintegrationApprovalModel, CaseReintegrationEditModel } from '../model';

@Injectable({providedIn:'root'})
export class CaseReintegrationService{
    apiName = 'CaseReIntegrationForms';
    constructor(private httpClient: HttpClient,private authService:AuthService) {

    }
     
    //case Reintegration

    caseReintegrationSave(model:CaseReintegrationEditModel){
        return this.httpClient.post(`${environment.apiUrl}${'CaseReIntegrationForms'}`,model,this.authService.getRequestHeader());
    }

    getCaseReintegrationById(caseReintegrationId) {
        return this.httpClient.get<any>(environment.apiUrl + 'CaseReIntegrationForms' +'/'+caseReintegrationId,this.authService.getRequestHeader());
    }

    caseReintegrationApproval(caseReintegrationId,model:CaseReintegrationApprovalModel){
        return this.httpClient.patch(`${environment.apiUrl}${'CaseReIntegrationForms'}/${caseReintegrationId}/Approvals`,model,this.authService.getRequestHeader());
    }
}