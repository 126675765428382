import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponseModel } from '../model/shared/api-response.model';
import { AuthService } from '../../core/auth';
import { DetailAssessmentPartB21EditModel, DetailAssessmentPartB22EditModel, DetailAssessmentPartB23EditModel, DetailAssessmentPartB31EditModel, DetailAssessmentPartB32EditModel, DetailAssessmentPartB33EditModel, DetailAssessmentPartB34EditModel } from '../model';

@Injectable({
    providedIn: 'root'
})
export class DetailAssessmentFormService {
    apiName = 'IntakeForms';
    constructor(private httpClient: HttpClient, private authService: AuthService) {

    }
    
    //PartB21
    saveDraftPartB21(model:DetailAssessmentPartB21EditModel) {
        return this.httpClient.post(environment.apiUrl + 'DetailAssessmentFormPartB2As/Drafts',model,this.authService.getRequestHeader());
    }
    updateDraftPartB21(id:number,model:DetailAssessmentPartB21EditModel) {
        const formData = new FormData();
       
        formData.append('draftIntakeFormRequestData', JSON.stringify(model));
        return this.httpClient.put(environment.apiUrl + 'DetailAssessmentFormPartB2As/Drafts'+'/'+id,formData,this.authService.getRequestHeader());
    }

    savePartB21(model:DetailAssessmentPartB21EditModel) {
        return this.httpClient.post(environment.apiUrl + 'DetailAssessmentFormPartB2As',model,this.authService.getRequestHeader());
    }
    updatePartB21(id:number,model:DetailAssessmentPartB21EditModel) {
        const formData = new FormData();
       
        formData.append('draftIntakeFormRequestData', JSON.stringify(model));
        return this.httpClient.put(environment.apiUrl + 'DetailAssessmentFormPartB2As'+'/'+id,formData,this.authService.getRequestHeader());
    }

    

    //PartB22

    saveDraftPartB22(model:DetailAssessmentPartB22EditModel) {
       
        return this.httpClient.post(environment.apiUrl + 'DetailAssessmentFormPartB2Bs/Drafts',model,this.authService.getRequestHeader());
    }
    updateDraftPartB22(id:number,model:DetailAssessmentPartB22EditModel) {
        
        return this.httpClient.put(environment.apiUrl + 'DetailAssessmentFormPartB2Bs/Drafts'+'/'+id,model,this.authService.getRequestHeader());
    }

    savePartB22(model:DetailAssessmentPartB22EditModel) {
       
        return this.httpClient.post(environment.apiUrl + 'DetailAssessmentFormPartB2Bs',model,this.authService.getRequestHeader());
    }
    updatePartB22(id:number,model:DetailAssessmentPartB22EditModel) {
        
        return this.httpClient.put(environment.apiUrl + 'DetailAssessmentFormPartB2Bs'+'/'+id,model,this.authService.getRequestHeader());
    }

    //PartB23

    saveDraftPartB23(model:DetailAssessmentPartB23EditModel) {
       
        return this.httpClient.post(environment.apiUrl + 'DetailAssessmentFormPartB2Cs/Drafts',model,this.authService.getRequestHeader());
    }
    updateDraftPartB23(id:number,model:DetailAssessmentPartB23EditModel) {
        
        return this.httpClient.put(environment.apiUrl + 'DetailAssessmentFormPartB2Cs/Drafts'+'/'+id,model,this.authService.getRequestHeader());
    }

    savePartB23(model:DetailAssessmentPartB23EditModel) {
       
        return this.httpClient.post(environment.apiUrl + 'DetailAssessmentFormPartB2Cs',model,this.authService.getRequestHeader());
    }
    updatePartB23(id:number,model:DetailAssessmentPartB23EditModel) {
        
        return this.httpClient.put(environment.apiUrl + 'DetailAssessmentFormPartB2Cs'+'/'+id,model,this.authService.getRequestHeader());
    }
    


    //PartB31

    saveDraftPartB31(model:DetailAssessmentPartB31EditModel) {
       
        return this.httpClient.post(environment.apiUrl + 'DetailAssessmentFormPartB3As/Drafts',model,this.authService.getRequestHeader());
    }
    updateDraftPartB31(id:number,model:DetailAssessmentPartB31EditModel) {
        
        return this.httpClient.put(environment.apiUrl + 'DetailAssessmentFormPartB3As/Drafts'+'/'+id,model,this.authService.getRequestHeader());
    }

    savePartB31(model:DetailAssessmentPartB31EditModel) {
       
        return this.httpClient.post(environment.apiUrl + 'DetailAssessmentFormPartB3As',model,this.authService.getRequestHeader());
    }
    updatePartB31(id:number,model:DetailAssessmentPartB31EditModel) {
        
        return this.httpClient.put(environment.apiUrl + 'DetailAssessmentFormPartB3As'+'/'+id,model,this.authService.getRequestHeader());
    }
    

    //PartB32

    saveDraftPartB32(model:DetailAssessmentPartB32EditModel) {
       
        return this.httpClient.post(environment.apiUrl + 'DetailAssessmentFormPartB3Bs/Drafts',model,this.authService.getRequestHeader());
    }
    updateDraftPartB32(id:number,model:DetailAssessmentPartB32EditModel) {
        
        return this.httpClient.put(environment.apiUrl + 'DetailAssessmentFormPartB3Bs/Drafts'+'/'+id,model,this.authService.getRequestHeader());
    }

    savePartB32(model:DetailAssessmentPartB32EditModel) {
       
        return this.httpClient.post(environment.apiUrl + 'DetailAssessmentFormPartB3Bs',model,this.authService.getRequestHeader());
    }
    updatePartB32(id:number,model:DetailAssessmentPartB32EditModel) {
        
        return this.httpClient.put(environment.apiUrl + 'DetailAssessmentFormPartB3Bs'+'/'+id,model,this.authService.getRequestHeader());
    }


    //PartB33

    saveDraftPartB33(model:DetailAssessmentPartB33EditModel) {
       
        return this.httpClient.post(environment.apiUrl + 'DetailAssessmentFormPartB3Cs/Drafts',model,this.authService.getRequestHeader());
    }
    updateDraftPartB33(id:number,model:DetailAssessmentPartB33EditModel) {
        
        return this.httpClient.put(environment.apiUrl + 'DetailAssessmentFormPartB3Cs/Drafts'+'/'+id,model,this.authService.getRequestHeader());
    }

    savePartB33(model:DetailAssessmentPartB33EditModel) {
       
        return this.httpClient.post(environment.apiUrl + 'DetailAssessmentFormPartB3Cs',model,this.authService.getRequestHeader());
    }
    updatePartB33(id:number,model:DetailAssessmentPartB33EditModel) {
        
        return this.httpClient.put(environment.apiUrl + 'DetailAssessmentFormPartB3Cs'+'/'+id,model,this.authService.getRequestHeader());
    }

    //PartB34

    saveDraftPartB34(model:DetailAssessmentPartB34EditModel) {
       
        return this.httpClient.post(environment.apiUrl + 'DetailAssessmentFormPartB3Ds/Drafts',model,this.authService.getRequestHeader());
    }
    updateDraftPartB34(id:number,model:DetailAssessmentPartB34EditModel) {
        
        return this.httpClient.put(environment.apiUrl + 'DetailAssessmentFormPartB3Ds/Drafts'+'/'+id,model,this.authService.getRequestHeader());
    }

    savePartB34(model:DetailAssessmentPartB34EditModel) {
       
        return this.httpClient.post(environment.apiUrl + 'DetailAssessmentFormPartB3Ds',model,this.authService.getRequestHeader());
    }
    updatePartB34(id:number,model:DetailAssessmentPartB34EditModel) {
        
        return this.httpClient.put(environment.apiUrl + 'DetailAssessmentFormPartB3Ds'+'/'+id,model,this.authService.getRequestHeader());
    }
    
}