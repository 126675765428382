import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth';
import { FormGroupEnum } from '../model/enum/form-group.enum';

@Injectable({providedIn:'root'})
export class QuestionService{
    apiName = 'Questions';
    constructor(private httpClient:HttpClient,private authService:AuthService){

    }
    getRiskAssessment(){
        return this.httpClient.get<any>(environment.apiUrl+this.apiName+'/RiskAssessments?PageSize=500',this.authService.getRequestHeader())
    }
    getQuestions(formGroup:FormGroupEnum){
        return this.httpClient.get<any>(environment.apiUrl+this.apiName+'?formGroup='+formGroup+'&PageSize=500',this.authService.getRequestHeader())
    }

}