export class AddDraftDetailAssessmentFormPartB2ARequest {
   detailAssessmentFormPartB2AData: string;
}


export class DetailAssessmentPartB21EditModel {
   draftId:number;
   draftName: string;
   caseId:number;
   officeId:number;
   verifiedDate:string;
   isReferredOffice:boolean;
   balancedDietProvided:string;
   //notProvidedReasons:number[];
   //notProvidedReasonOthers:string;
   //hasStandardAccommodation:string;
   //notStandardReasons:number[];
   //notStandardReasonOthers:string;
   gotCleanDresses:number;
   observation:string;
}



