
export class FormPermissionEditModel{
    formPermissions:FormPermissionEditModels[];
}

export class FormPermissionEditModels {
   id:number;
   roleId:number;
   roleName:string;
   access:string;
}