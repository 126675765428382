import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth';

@Injectable({
  providedIn: 'root'
})
export class ProfilePdfGenerateService {
  apiName = 'Prints';

  constructor(private httpClient: HttpClient,private authService:AuthService) { }

  getDataofSubmittedForms(formType:string,caseId:number) {
    return this.httpClient.get<any>(environment.apiUrl + this.apiName+'/'+caseId+'/'+formType,this.authService.getRequestHeader());

  }
}
